import SectionTitleWidget from './SectionTitleWidget';
import SingleImageWidget from './SingleImageWidget';
import TextBoxWidget from './TextBoxWidget';
import AccentNavigationWidget from './AccentNavigationWidget';
import HtmlWidget from './HtmlWidget';
import LeadImageWidget from './LeadImageWidget';
import SubscribeWidget from './SubscribeWidget';
import TextBoxTwoColsWidget from './TextBoxTwoColsWidget';
import TextBoxThreeColsWidget from './TextBoxThreeColsWidget';
import ContactFormWidget from './ContactFormWidget';
import ArticleListWidget from './ArticleListWidget';
import CtaWidget from './CtaWidget';
import TestimonialSliderWidget from './TestimonialSliderWidget';
import MealsSliderWidget from './MealsSliderWidget';
import GallerySliderWidget from './GallerySliderWidget';
import AccentImageWidget from './AccentImageWidget';
import MealsWidget from './MealsWidget';
import MenuWidget from './MenuWidget';
import AccentTestimonialWidget from './AccentTestimonialWidget';
import MediaStripWidget from './MediaStripWidget';
import LogoStripWidget from './LogoStripWidget';
import SpotlightWidget from './SpotlightWidget';
import EventReservationFormWidget from './EventReservationFormWidget';

export default {
  SectionTitleWidget,
  SingleImageWidget,
  TextBoxWidget,
  AccentNavigationWidget,
  HtmlWidget,
  LeadImageWidget,
  SubscribeWidget,
  TextBoxTwoColsWidget,
  TextBoxThreeColsWidget,
  ContactFormWidget,
  ArticleListWidget,
  CtaWidget,
  TestimonialSliderWidget,
  MealsSliderWidget,
  GallerySliderWidget,
  AccentImageWidget,
  MealsWidget,
  MenuWidget,
  AccentTestimonialWidget,
  MediaStripWidget,
  LogoStripWidget,
  SpotlightWidget,
  EventReservationFormWidget,
};
