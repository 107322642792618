import React from "react";

const Input = ({
  r,
  type = "text",
  name,
  label,
  placeholder,
  wrapperProps = {},
  ...props
}) => (
  <div className={type !== "hidden" ? "form-group" : ""} {...wrapperProps}>
    {label && <label className="control-label">{label}</label>}

    <input
      type={type}
      name={name}
      ref={r}
      placeholder={placeholder}
      className="form-control"
      {...props}
    />
  </div>
);

export default Input;
