import React from "react";
import { useForm } from "react-hook-form";
import Input from "./Input";
import Select from "./Select";
import TypeSelector from "./TypeSelector";
import CheckoutTable from "./CheckoutTable";
import OrderItems from "./OrderItems";
import Loading from "./Loading";
import { CartContext } from "../raketa-cart";

const rand = () => Math.random().toString(36).substring(7).substring(0, 4);

const AREAS = [
  "Център",
  "Тракия",
  "Кършияка",
  "Мараша",
  "Каменица 1",
  "Каменица 2",
  "Беломорски",
  "Въстанически",
  "Гагарин",
  "Западен",
  "Захарна фабрика",
  "Изгрев",
  "Коматево",
  "Кючук Париж",
  "Младежки хълм",
  "Остромила",
  "Прослав",
  "Старият град",
  "Столипиново",
  "Съдийски",
  "Филипово",
  "Христо Смирненски",
  "Централна гара",
  "Шекер Махала",
  "Южен",
];

const DeliveryForm = ({ time_slots, types, type, onChangeType }) => {
  const today = new Date();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      data: {
        order_id: `${rand().toUpperCase()}-${today.getHours()}${today.getMinutes()}`,
      },
    },
  });
  const { clear } = React.useContext(CartContext);
  const onSubmit = (values) => {
    values.data.encoded_address = encodeURI(
      `Plovdiv+${values.data.area}+${values.data.address}`
    );

    fetch("https://forms.raketa.cloud/submit/6UvWiVvp2l", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        const { form } = data;
        document.querySelector(".loader-container").classList.remove("hide");
        clear();

        window.location.href = form.success_location;
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  return (
    <form className="checkout-form" onSubmit={handleSubmit(onSubmit)}>
      <Loading />

      <div className="black-bg spacing-bottom-medium">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="spacing-bottom-small">
                <h5>Метод на получаване</h5>

                <TypeSelector
                  types={types}
                  value={type}
                  onChange={onChangeType}
                />
              </div>

              <Input r={register} type="hidden" name="data[order_id]" />

              <div className="spacing-bottom-small">
                <h5>Твоите контакти</h5>

                <Input
                  r={register}
                  name="data[name]"
                  label="Имена"
                  placeholder="Хари Потър"
                  required
                />

                <Input
                  r={register}
                  name="data[phone]"
                  label="Телефон, на който да потвърдим"
                  placeholder="088×××××××"
                  required
                  pattern="0\d{9}"
                />

                <Input
                  r={register}
                  type="email"
                  name="data[email]"
                  label="Email"
                  placeholder="harry@hogwarts.magi"
                  required
                />
              </div>

              <div className="spacing-bottom-small">
                <h5>Кога да бъде готово?</h5>

                <Select r={register} name="data[when]" required>
                  {time_slots.slots.map((slot, idx) => (
                    <option key={slot} value={slot}>
                      {idx === 0 ? "Най-скоро, " : ""}
                      {slot}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="spacing-bottom-small">
                <h5>За кой адрес?</h5>

                <Select
                  r={register}
                  name="data[area]"
                  label="Район в Пловдив"
                  placeholder="Избери квартал..."
                  required
                >
                  {AREAS.map((a) => (
                    <option key={a} value={a}>
                      {a}
                    </option>
                  ))}
                </Select>

                <Input
                  r={register}
                  name="data[address]"
                  label="Адрес"
                  placeholder="ул. Дъмбълдор 3А или бл. 55, вх. Д"
                  required
                />

                <div className="form-group">
                  <label className="control-label">Бележки за адреса</label>
                  <textarea
                    ref={register}
                    name="data[address_notes]"
                    className="form-control"
                    placeholder="Домофонът е в ляво, натиснете 055 и камбанката, за да ви отворим"
                  />
                </div>
              </div>

              <div className="spacing-bottom-small">
                <h4>Бележка към поръчката</h4>
                <div className="form-group">
                  <textarea
                    ref={register}
                    name="data[order_notes]"
                    className="form-control"
                    placeholder="Желаете сосът да е отделно или пък салатата разбъркана?"
                  />
                </div>
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-6">
              <div className="order-pannel">
                <h4>Безплатна доставка</h4>
                <p>
                  Безплатна доставка до дом или офис в Пловдив в рамките на 60
                  минути. Прилежно разделени топли и студени ястия в
                  рециклируеми опаковки.
                </p>

                <CheckoutTable type={type} />

                <OrderItems r={register} />

                <input
                  ref={register}
                  type="hidden"
                  name="data[order_type]"
                  value={type}
                />

                <div className="spacing-top-small">
                  <button type="submit" className="btn-success lg">
                    Поръчай
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DeliveryForm;
