import React from "react";
import PropTypes from "prop-types";
import Layout from "./frontend/Layout";
import MenuProvider from "./MenuProvider";
import { LanguageProvider } from "./LanguageContext";

import LIBRARY from "./widgets";

const PageRender = ({
  page,
  page_context,
  lunchMenu,
  weeklyMenu,
  language,
}) => (
  <div>
    <LanguageProvider language={language}>
      <MenuProvider lunchMenu={lunchMenu} weeklyMenu={weeklyMenu}>
        <Layout page_context={page_context}>
          {page.widgets.map((widget, idx) =>
            React.createElement(
              LIBRARY[widget.component],
              Object.assign({}, { key: idx }, widget.settings)
            )
          )}
        </Layout>
      </MenuProvider>
    </LanguageProvider>
  </div>
);

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
