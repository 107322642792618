import React from "react";
import PropTypes from "prop-types";
import { Img, Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import formatPrice from "../formatPrice";

const titles = (language, title) => {
  var en = {
    salads: "Salads",
    soups: "Soups",
    main: "Main",
    dessert: "Dessert",
  };
  var bg = {
    salads: "Салати",
    soups: "Супи",
    main: "Основни",
    dessert: "Десерти",
  };

  return language == "en" ? en[title] : bg[title];
};

const Meal = ({ meal }, { language }) => (
  <div className="menu-item">
    <h5 className="dish-title">
      <span className="title-text">{meal.title}</span>

      <span className="price">
        {formatPrice(new Number(meal.price).toFixed(2), language)}
      </span>
    </h5>
    <div className="item-footer">
      <p className="serving-info">
        {(meal.weight || 0) !== 0 && (
          <span className="meal-wight">{meal.weight} г</span>
        )}

        {(meal.kilocalories || 0) !== 0 && (
          <React.Fragment>
            <span className="separator"></span>
            {meal.kilocalories.toString().replace(".0", "")} kcal
          </React.Fragment>
        )}

        {meal.tags.length > 0 && (
          <span className="tags">
            <span
              className="separator"
              style={{ position: "relative", top: "-2px" }}
            ></span>
            {meal.tags.map((tag) => (
              <span
                key={tag.id}
                className="tag-text"
                style={{ color: tag.color }}
              >
                {tag.name}
              </span>
            ))}
          </span>
        )}
      </p>

      {meal.allergens.length > 0 && (
        <React.Fragment>
          <span className="separator"></span>

          <div className="allergens">
            {meal.allergens.map((allergen) => (
              <div key={allergen.id} className="tooltip-wrapper">
                <span className="tooltip black">
                  <span className="tooltip-text">{allergen.name}</span>
                </span>
                <Img
                  src={allergen.image}
                  variant="original"
                  className="invert"
                />
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
    {meal.description && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: meal.description }}
      />
    )}
  </div>
);

Meal.contextTypes = {
  language: PropTypes.any,
};

const MealGroup = ({ title, items }) => (
  <div className="category">
    <h2 className="category-title">{title}</h2>

    {items.map((meal) => (
      <Meal key={meal.id} meal={meal} />
    ))}
  </div>
);

const getMenu = (lunchMenu, weeklyMenu, variant) => {
  const menus = { lunch: lunchMenu, weekly: weeklyMenu };
  return menus[variant];
};

const MenuWidget = (
  { variant, defaultText, containerSettings },
  { lunchMenu, weeklyMenu, language }
) => {
  const menu = getMenu(lunchMenu, weeklyMenu, variant);

  if (!menu)
    return (
      <Container settings={containerSettings}>
        <div
          className="container"
          dangerouslySetInnerHTML={{ __html: defaultText }}
        />
      </Container>
    );

  return (
    <Container settings={containerSettings}>
      <div className="food-menu">
        <div className="container">
          <div className="row">
            {menu.salads_list.length > 0 || menu.main_list.length > 0 ? (
              <div className="col-6">
                {menu.salads_list.length > 0 ? (
                  <MealGroup
                    title={titles(language, "salads")}
                    items={menu.salads_list}
                  />
                ) : null}

                {menu.main_list.length > 0 ? (
                  <MealGroup
                    title={titles(language, "main")}
                    items={menu.main_list}
                  />
                ) : null}
              </div>
            ) : null}

            {menu.soups_list.length > 0 || menu.desserts_list.length > 0 ? (
              <div className="col-6">
                {menu.soups_list.length > 0 ? (
                  <MealGroup
                    title={titles(language, "soups")}
                    items={menu.soups_list}
                  />
                ) : null}

                {menu.desserts_list.length > 0 ? (
                  <MealGroup
                    title={titles(language, "dessert")}
                    items={menu.desserts_list}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

MenuWidget.contextTypes = {
  lunchMenu: PropTypes.any,
  weeklyMenu: PropTypes.any,
  language: PropTypes.any,
};

MenuWidget.title = "Menu";
MenuWidget.category = "General";

MenuWidget.propTypes = {
  variant: PropTypes.string.isRequired,
};

MenuWidget.defaults = {
  variant: "lunch",
  defaultText: "Lunch menu for today coming soon.",
};

MenuWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["lunch", "Lunch"],
      ["weekly", "Weekly"],
    ],
  },
  defaultText: { type: "custom", component: RichText },
};

export default MenuWidget;
