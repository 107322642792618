import React from "react";
import PropTypes from "prop-types";
import { Container, Img, List, TextInput } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const ArticleListWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="article-list">
      {list.map((article, idx) => (
        <article key={idx} className="article-item">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <a href={article.link}>
                  <Img src={article.image} variant="article_thumb" />
                </a>
              </div>

              <div className="col-6">
                <h4 className="title">
                  <a href={article.link}>{article.title}</a>
                </h4>
                <time className="date">{article.meta}</time>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: article.description }}
                />
              </div>
            </div>
          </div>
        </article>
      ))}
    </div>
  </Container>
);

ArticleListWidget.title = "Article List";
ArticleListWidget.category = "Content";

ArticleListWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ArticleListWidget.defaults = {
  list: [
    {
      id: 1,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пъпешът - греховната полезна сладост, стичаща се по небцето",
      meta: "23.04.2017",
      description:
        "Съдейки по ревютата се разбира, че менюто в ресторанта е семпло и изискано, съгласно наложените тенденции и практики на най-добрите световни ресторанти. То се сменя основно два пъти в годината – в началото на зимата и в началото на лятото.",
    },
    {
      id: 2,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Летни ястия - най-добрите храни за горещия",
      meta: "23.04.2017",
      description:
        "Съдейки по ревютата се разбира, че менюто в ресторанта е семпло и изискано, съгласно наложените тенденции и практики на най-добрите световни ресторанти. То се сменя основно два пъти в годината – в началото на зимата и в началото на лятото.",
    },
    {
      id: 3,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Седемте имена на Пловдив - история и култура във вечния град",
      meta: "23.04.2017",
      description:
        "Съдейки по ревютата се разбира, че менюто в ресторанта е семпло и изискано, съгласно наложените тенденции и практики на най-добрите световни ресторанти. То се сменя основно два пъти в годината – в началото на зимата и в началото на лятото.",
    },
  ],
  containerSettings: { theme: "light" },
};

const ArticleListItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Meta"
      onChange={(value) => onChangeItem("meta", value)}
      value={settings.meta}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

ArticleListItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

ArticleListWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      label="Articles"
      listItem={(settings, onChangeItem) => (
        <ArticleListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        link: "#",
        image: "http://placehold.it/360x240",
        title: "Седемте имена на Пловдив - история и култура във вечния град",
        meta: "23.04.2017",
        description:
          "Съдейки по ревютата се разбира, че менюто в ресторанта е семпло и изискано, съгласно наложените тенденции и практики на най-добрите световни ресторанти. То се сменя основно два пъти в годината – в началото на зимата и в началото на лятото.",
      }}
    />
  </div>
);

export default ArticleListWidget;
