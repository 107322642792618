import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import Recaptcha from "react-google-invisible-recaptcha";

import Input from "../utils/Input";
import Textarea from "../utils/Textarea";
import httpPost from "../utils/Http";

class ContactFormWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      sent: false,
      errored: false,
      buttonDisabled: false,
      errors: {},
    };

    this.onRecaptchaResolved = this.onRecaptchaResolved.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.collectErrors = this.collectErrors.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleUpdate(field, value) {
    this.setState({ [field]: value });
  }

  collectErrors() {
    const {
      nameErrorMessage,
      emailErrorMessage,
      messageErrorMessage,
    } = this.props;
    const { name, email, message } = this.state;
    const errors = {};

    if (name.trim() === "") errors["name"] = nameErrorMessage;
    if (email.trim() === "") errors["email"] = emailErrorMessage;
    if (message.trim() === "") errors["message"] = messageErrorMessage;

    return errors;
  }

  onSubmit(e) {
    e.preventDefault();

    const errors = this.collectErrors();

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ buttonDisabled: true });
      this.captcha.execute();
    } else {
      this.captcha.reset();
    }
  }

  onRecaptchaResolved() {
    const { name, email, phone, club, message } = this.state;
    const captcha_value = this.captcha.getResponse();

    const params = {
      contact: { name, email, phone, club, message, captcha_value },
    };

    httpPost("/contacts", params)
      .then((resp) => {
        if (resp.ok) {
          this.setState({ sent: true });
        } else {
          this.setState({ sent: true, errored: true });
        }
      })
      .catch(() => {
        this.setState({ sent: true, errored: true });
      });
  }

  render() {
    const {
      nameLabel,
      namePlaceholder,
      emailLabel,
      emailPlaceholder,
      messageLabel,
      messagePlaceholder,
      title,
      description,
      buttonLabel,
      successMessage,
      successMessageBtnLabel,
      errorMessage,
      containerSettings,
    } = this.props;

    const {
      errored,
      sent,
      errors,
      name,
      email,
      message,
      buttonDisabled,
    } = this.state;

    return (
      <Container settings={containerSettings}>
        <div className="contact-from">
          {sent && (
            <div className="success-message container">
              <div className="success-title">
                <h3 className="title">
                  {errored ? errorMessage : successMessage}
                </h3>
              </div>
              {successMessageBtnLabel && (
                <a href="/" className="btn-primary">
                  {successMessageBtnLabel}
                </a>
              )}
            </div>
          )}

          {!sent && (
            <form
              action="/contacts"
              method="post"
              onSubmit={(e) => this.onSubmit(e)}
            >
              <div className="container">
                <div className="row">
                  <div className="shift-2">
                    <h3 className="title">{title}</h3>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />

                    <div className="row">
                      <div className="col-6">
                        <Input
                          type="text"
                          label={nameLabel}
                          name="contact[name]"
                          error={"name" in errors ? errors["name"] : false}
                          value={name}
                          placeholder={namePlaceholder}
                          onChange={(value) => this.handleUpdate("name", value)}
                        />
                      </div>

                      <div className="col-6">
                        <Input
                          type="text"
                          label={emailLabel}
                          name="contact[email]"
                          error={"email" in errors ? errors["email"] : false}
                          value={email}
                          placeholder={emailPlaceholder}
                          onChange={(value) =>
                            this.handleUpdate("email", value)
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <Textarea
                          label={messageLabel}
                          name="contact[message]"
                          error={
                            "message" in errors ? errors["message"] : false
                          }
                          value={message}
                          onChange={(value) =>
                            this.handleUpdate("message", value)
                          }
                          placeholder={messagePlaceholder}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn-primary "
                      disabled={buttonDisabled}
                    >
                      {buttonLabel}
                    </button>

                    <Recaptcha
                      ref={(el) => {
                        this.captcha = el;
                      }}
                      // TODO: The site key should be a configuration
                      sitekey="6LfjF28UAAAAADY7gZIt04qq2mdUAF-juqrZYzJT" // Smokini's key
                      // sitekey="6Ld6aW8UAAAAAAIvBvJvElOtoSST57Hvvp4B1LXj" // # Svetlio M's dev key
                      onResolved={this.onRecaptchaResolved}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </Container>
    );
  }
}

ContactFormWidget.title = "Contact Form";
ContactFormWidget.category = "Lead Generation";

ContactFormWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,

  successMessage: PropTypes.string.isRequired,
  successMessageBtnLabel: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  namePlaceholder: PropTypes.string.isRequired,
  nameErrorMessage: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  emailPlaceholder: PropTypes.string.isRequired,
  emailErrorMessage: PropTypes.string.isRequired,
  messageLabel: PropTypes.string.isRequired,
  messagePlaceholder: PropTypes.string.isRequired,
  messageErrorMessage: PropTypes.string.isRequired,

  containerSettings: PropTypes.object.isRequired,
};

ContactFormWidget.defaults = {
  title: "Ще се радваме да ни споделите какво мислите",
  description:
    "<p>Намираме се на ул. „Отец Паисий” 12 (известна като “Златарска”), паралелнa на главната улица на Пловдив. Близо до нас са древните разкопки на Античния стадион, площад „Джумая”, както и пътя за Стария град.</p>",
  buttonLabel: "Изпратете обратна връзка",

  successMessage: "Благодарим за отделеното време!",
  successMessageBtnLabel: "Към началната страница",
  errorMessage: "Възникна технически проблем, моля опитайте по-късно.",

  nameLabel: "Вашето име",
  namePlaceholder: "Вашето име",
  nameErrorMessage: "Mоля попълнете Вашето име",

  emailLabel: "E-mail адрес",
  emailPlaceholder: "Вашият електронен адрес",
  emailErrorMessage: "Mоля попълнете Вашия e-mail адрес",

  messageLabel: "Вашият коментар или препоръка",
  messagePlaceholder: "Вашият коментар или препоръка",
  messageErrorMessage: "Mоля попълнете Вашият коментар или препоръка",

  containerSettings: { theme: "blue" },
};

ContactFormWidget.adminFields = {
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  buttonLabel: { type: "text" },

  successMessage: { type: "text" },
  successMessageBtnLabel: { type: "text" },
  errorMessage: { type: "text" },

  nameLabel: { type: "text" },
  namePlaceholder: { type: "text" },
  nameErrorMessage: { type: "text" },

  emailLabel: { type: "text" },
  emailPlaceholder: { type: "text" },
  emailErrorMessage: { type: "text" },

  messageLabel: { type: "text" },
  messagePlaceholder: { type: "text" },
  messageErrorMessage: { type: "text" },
};

export default ContactFormWidget;
