import React, { useState } from "react";
import { ImageInput, MediaManager } from "@raketa-cms/raketa-image-picker";

const updateSettings = (settings, key, value) =>
  Object.assign({}, settings, { [key]: value });

const MetaEditor = ({ name, value }) => {
  const [settings, setSettings] = useState(value);

  return (
    <div className="meta-editor">
      <h3>Meta</h3>

      <div className="form-group">
        <label className="control-label">Title</label>
        <input
          type="text"
          className="form-control"
          required
          value={settings.title}
          onChange={(e) =>
            setSettings(updateSettings(settings, "title", e.target.value))
          }
        />
        <div className="help-block">
          Used for &lt;title&gt; and OpenGraph title
        </div>
      </div>

      <div className="form-group">
        <label className="control-label">Description</label>
        <textarea
          className="form-control"
          required
          value={settings.description}
          onChange={(e) =>
            setSettings(updateSettings(settings, "description", e.target.value))
          }
        />
        <div className="help-block">
          Used for &lt;description&gt; and OpenGraph description
        </div>
      </div>

      <br />

      <h3>Open Graph</h3>
      <div className="form-group">
        <label className="control-label">Title</label>
        <input
          type="text"
          className="form-control"
          value={settings.opengraph_title}
          onChange={(e) =>
            setSettings(
              updateSettings(settings, "opengraph_title", e.target.value)
            )
          }
        />
        <div className="help-block">
          Used for &lt;title&gt; and OpenGraph title
        </div>
      </div>

      <div className="form-group">
        <label className="control-label">Description</label>
        <textarea
          className="form-control"
          value={settings.opengraph_description}
          onChange={(e) =>
            setSettings(
              updateSettings(settings, "opengraph_description", e.target.value)
            )
          }
        />
        <div className="help-block">
          Used for &lt;description&gt; and OpenGraph description
        </div>
      </div>
      <ImageInput
        mediaManager={new MediaManager("/")}
        image={settings.opengraph_image}
        onChange={(image) =>
          setSettings(updateSettings(settings, "opengraph_image", image))
        }
      />
      <br />

      <h3>JSON Schema</h3>

      <div className="form-group">
        <label className="control-label">JSON Schema</label>
        <textarea
          className="form-control"
          value={settings.schema}
          onChange={(e) =>
            setSettings(updateSettings(settings, "schema", e.target.value))
          }
        />
        <div className="help-block">
          Used for JSON schema, content put here will be wrapped in
          &lt;script&gt;
        </div>
      </div>

      <input type="hidden" name={name} value={JSON.stringify(settings)} />
    </div>
  );
};

export default MetaEditor;
