import React from "react";
import { useCookieCart, CartContext } from "../raketa-cart";
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import StickyCart from "./StickyCart";

const Layout = ({ page_context, children }) => {
  const cart = useCookieCart();
  const { show_sticky_cart, cart_active } = page_context;

  return (
    <>
      <CartContext.Provider value={cart}>
        <SiteHeader page_context={page_context} />

        {children}

        {cart_active && show_sticky_cart && <StickyCart />}

        <SiteFooter page_context={page_context} />
      </CartContext.Provider>
    </>
  );
};

export default Layout;
