import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";

const TextBoxTwoColsWidget = ({ textOne, textTwo, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="row">
        <div className="col-6">
          <div className="text" dangerouslySetInnerHTML={{ __html: textOne }} />
        </div>
        <div className="col-6">
          <div className="text" dangerouslySetInnerHTML={{ __html: textTwo }} />
        </div>
      </div>
    </div>
  </Container>
);

TextBoxTwoColsWidget.title = "Text Two Columns";
TextBoxTwoColsWidget.category = "General";

TextBoxTwoColsWidget.propTypes = {
  textOne: PropTypes.string.isRequired,
  textTwo: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

TextBoxTwoColsWidget.defaults = {
  variant: "normal",
  textOne:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum porttitor semper est in porta. Vestibulum fringilla justo in magna ornare, non commodo justo consectetur. Mauris ut tristique nisi, sed tristique lectus. Sed diam mauris, aliquet quis imperdiet sed, viverra sit amet lorem. Quisque urna sapien, molestie a bibendum nec, tempor vitae magna.",
  textTwo:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum porttitor semper est in porta. Vestibulum fringilla justo in magna ornare, non commodo justo consectetur. Mauris ut tristique nisi, sed tristique lectus. Sed diam mauris, aliquet quis imperdiet sed, viverra sit amet lorem. Quisque urna sapien, molestie a bibendum nec, tempor vitae magna.",
  containerSettings: { theme: "light" },
};

TextBoxTwoColsWidget.adminFields = {
  textOne: { type: "custom", component: RichText },
  textTwo: { type: "custom", component: RichText },
};

TextBoxTwoColsWidget.dialogSize = "large";

export default TextBoxTwoColsWidget;
