import React from "react";
import { CartContext } from "../raketa-cart";
import StepInput from "../frontend/StepInput";
import AddToCart from "../frontend/AddToCart";
import getDataLayer from "./getDataLayer";

const rand = () => Math.random().toString(36).substring(7);

const CartTable = ({ type, discount }) => {
  const { cart, update, remove } = React.useContext(CartContext);

  const cart_total = cart.reduce(
    (acc, i) => acc + parseFloat(i.price) * i.quantity,
    0
  );

  const discount_total = discount ? (cart_total / 100) * discount : 0;
  const grand_total = discount ? cart_total - discount_total : cart_total;

  const hasUtensils =
    cart.findIndex((i) => i.sku === "product-utensils") !== -1;

  return (
    <>
      <table className="cart-table">
        <thead>
          <tr>
            <th>Продукти</th>
            <th className="bool" style={{ width: "250px" }}>
              Брой
            </th>
            <th className="number" style={{ width: "150px" }}>
              Цена
            </th>
          </tr>
        </thead>

        <tbody>
          {cart.map((item) => (
            <tr key={item.id}>
              <td>
                <div className="title-wrapper">
                  {item.image && <img src={item.image} alt={item.title} />}
                  <span>{item.title}</span>
                </div>
              </td>
              <td className="bool">
                <StepInput
                  value={item.quantity}
                  onChange={(quantity) => {
                    if (quantity === 0) remove(item.sku);
                    else update(item.sku, { quantity });
                  }}
                />
              </td>
              <td className="number">
                {(parseFloat(item.price) * item.quantity).toFixed(2)} лв.
              </td>
            </tr>
          ))}

          {!hasUtensils && (
            <tr>
              <td colSpan={3}>
                <AddToCart
                  className="btn-utensils"
                  label="Добави прибори и салфетки"
                  product={{
                    id: rand(),
                    sku: `product-utensils`,
                    title: "Прибори и салфетки",
                    price: 0,
                  }}
                />
              </td>
            </tr>
          )}
        </tbody>

        <tfoot>
          <tr>
            <th colSpan={2} className="separator">
              Междинна сума
            </th>
            <td className="separator number">{cart_total.toFixed(2)} лв.</td>
          </tr>
          {type === "delivery" && (
            <tr>
              <td>&nbsp;</td>
              <th>Безплатна доставка</th>
              <td className="number">0,00 лв.</td>
            </tr>
          )}
          {type === "pickup" && (
            <tr>
              <td>&nbsp;</td>
              <th>Отстъпка</th>
              <td className="number">-{discount_total.toFixed(2)} лв.</td>
            </tr>
          )}
          <tr>
            <td>&nbsp;</td>
            <th className="separator">Обща сума</th>
            <td className="separator number">{grand_total.toFixed(2)} лв.</td>
          </tr>
        </tfoot>
      </table>

      <div
        dangerouslySetInnerHTML={{
          __html: getDataLayer(cart.length, cart_total.toFixed(2)),
        }}
      />
    </>
  );
};

export default CartTable;
