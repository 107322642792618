import React from "react";
import PropTypes from "prop-types";
import { Container, Img, List, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const MediaStripWidget = ({ title, list, containerSettings }) => (
  <Container className="media-strip" settings={containerSettings}>
    <h3>{title}</h3>

    <div className="logos-wrapper">
      {list.map((item, idx) => (
        <React.Fragment key={idx}>
          {item.link ? (
            <a href={item.link}>
              <Img src={item.logo} variant="original" />
            </a>
          ) : (
            <Img src={item.logo} variant="original" />
          )}
        </React.Fragment>
      ))}
    </div>
  </Container>
);

MediaStripWidget.title = "Media Strip";
MediaStripWidget.category = "Content";

MediaStripWidget.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

MediaStripWidget.defaults = {
  title: "Smokini в медиите",
  list: [
    { id: 1, link: "#", logo: "http://placehold.it/300x90" },
    { id: 2, link: "#", logo: "http://placehold.it/300x90" },
  ],
  containerSettings: { theme: "light" },
};

const MediaStripListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Logo"
      onChange={(value) => onChangeItem("logo", value)}
      value={settings.logo}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

MediaStripListItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

MediaStripWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Title"
      onChange={(value) => onChange("title", value)}
      value={settings.title}
    />

    <List
      label="Images"
      listItem={(settings, onChangeItem) => (
        <MediaStripListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="link"
      template={{ link: "#", image: "http://placehold.it/300x90" }}
    />
  </div>
);

export default MediaStripWidget;
