import React from "react";
import CheckoutLayout from "../frontend/CheckoutLayout";
import CartPreview from "./CartPreview";

const ClosedForBusiness = ({ workingHours }) => (
  <>
    <div className="black-bg spacing-top-large spacing-bottom-small">
      <div className="store-title">
        <div className="container">
          <h1 className="title">Ух! Хвана ни извън работно време!</h1>
        </div>
      </div>
    </div>

    <div className="black-bg spacing-bottom-medium">
      <div className="container">
        <div className="row">
          <div className="col-8">
            <p>На линия сме за доставки в тези дни и часове:</p>
            {workingHours
              .filter((wd) => wd.active)
              .map((wd) => (
                <p>
                  {wd.day}: {wd.from} - {wd.to}
                </p>
              ))}

            <a href="/" className="btn-primary-alt">
              Към началната страница
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
);

const Cart = ({ page_context }) => {
  if (!page_context.is_delivery_open)
    return (
      <CheckoutLayout page_context={page_context}>
        <ClosedForBusiness workingHours={page_context.working_hours} />
      </CheckoutLayout>
    );

  return (
    <CheckoutLayout page_context={page_context}>
      <div className="black-bg spacing-top-large spacing-bottom-small">
        <div className="store-title">
          <div className="container">
            <h1 className="title">Твоята поръчка</h1>
          </div>
        </div>
      </div>

      <div className="black-bg spacing-bottom-medium">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <CartPreview />
            </div>
          </div>
        </div>
      </div>
    </CheckoutLayout>
  );
};

export default Cart;
