import React from "react";
import { Img, imageSrc } from "@raketa-cms/raketa-cms";
import AddToCart from "../frontend/AddToCart";

const rand = () => Math.random().toString(36).substring(7);

const Product = ({ product, page_context }) => {
  const {
    url,
    image,
    tags,
    title,
    price,
    price_formatted,
    description,
    weight,
    allergens,
    product_type,
  } = product;

  const { cart_active } = page_context;

  return (
    <div className="menu-card product-cart">
      <a href={url} className="product-image">
        <Img src={image} variant="card_horizontal" />
      </a>

      <div className="tags">
        {tags.map((tag) => (
          <span
            key={tag.id}
            className="tag-label"
            style={{
              backgroundColor: tag.color,
            }}
          >
            {tag.name}
          </span>
        ))}
      </div>

      <div className="text">
        <div className="title">
          <h6 className="title-text">
            <a href={url}>{title}</a>
          </h6>
        </div>

        <div className="card-meta">
          <p className="serving-info">
            {`${weight} ${product_type === "food" ? "g" : "ml"}`}
          </p>

          {allergens.length > 0 && (
            <>
              <span className="separator"></span>
              <div className="allergens">
                {allergens.map((allergen) => (
                  <div key={allergen.id} className="tooltip-wrapper">
                    <span className="tooltip light">
                      <span className="tooltip-text">{allergen.name}</span>
                    </span>
                    <Img src={allergen.image} variant="original" />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <p className="description">{description}</p>

        <div className="card-footer">
          <div className="card-cart">
            {cart_active && product.active && product.in_stock && (
              <AddToCart
                className="btn-primary-alt sm"
                label="Добави"
                product={{
                  id: rand(),
                  sku: `product-${product.id}`,
                  title,
                  price,
                  image: imageSrc(product.image, "thumb"),
                }}
              />
            )}

            <span className="price">{price_formatted}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
