import React from 'react';

const InputWithIcon = ({ type, placeholder, label, error, value, iconType, onChange }) => (
  <div className={`form-group ${error ? 'has-error' : ''}`}>
    <label htmlFor="">{label} <span className="required">*</span></label>

    <input
      className={`icon-input form-control ${iconType}`}
      type={type}
      placeholder={placeholder}
      // name={name}
      value={value}
      onChange={(e) => onChange(e.target.value, e)}
    />

    {error && <span className="help-block">{error}</span>}
  </div>
);

InputWithIcon.defaultProps = {
  type: 'text',
  placeholder: '',
  value: '',
};

export default InputWithIcon;
