import Rails from "rails-ujs";
import serverRedering from "./server_rendering";

Rails.start();

const menuNavigation = () => {
  let triggers = document.querySelectorAll(".navigation-wrapper");

  if (triggers.length > 0) {
    for (let i = 0; i < triggers.length; i++) {
      triggers[i].addEventListener("click", function (e) {
        e.target.parentElement.parentElement.parentElement.classList.toggle(
          "nav-open"
        );
      });
    }
  }
};

document.addEventListener(
  "DOMContentLoaded",
  function () {
    menuNavigation();
  },
  false
);
