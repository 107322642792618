import React from "react";
import { CartContext } from "../raketa-cart";
import StepInput from "./StepInput";
import AddToCart from "./AddToCart";

const rand = () => Math.random().toString(36).substring(7);

const StickyCart = () => {
  const { cart, update, remove } = React.useContext(CartContext);
  const [open, setOpen] = React.useState(false);

  const cart_total = cart.reduce(
    (acc, i) => acc + parseFloat(i.price) * i.quantity,
    0
  );

  const hasUtensils =
    cart.findIndex((i) => i.sku === "product-utensils") !== -1;

  return (
    <div className="sticky-cart-wrapper">
      <div className="container">
        <div className={`sticky-cart ${open ? "open" : ""}`}>
          <div className="cart-title" onClick={() => setOpen(!open)}>
            <h6 className="title">Кошница</h6>
          </div>

          <div className="cart-content">
            {cart.length === 0 && (
              <small>
                Вашата кошница е празна. <br />
                <a href="/menu">Към менюто</a>
              </small>
            )}

            {cart.length > 0 && (
              <>
                {cart.map((item) => (
                  <div key={item.id} className="cart-row">
                    <div className="item-title-wrapper">
                      <StepInput
                        value={item.quantity}
                        onChange={(quantity) => {
                          if (quantity === 0) remove(item.sku);
                          else update(item.sku, { quantity });
                        }}
                      />
                      <div className="title">
                        <span className="quantity">{`${item.quantity}x`}</span>
                        {item.title}
                      </div>
                    </div>
                    <div className="price">
                      {(parseFloat(item.price) * item.quantity).toFixed(2)} лв.
                    </div>
                  </div>
                ))}

                {!hasUtensils && (
                  <div className="cart-row">
                    <AddToCart
                      className="btn-utensils"
                      label="Добави прибори и салфетки"
                      product={{
                        id: rand(),
                        sku: `product-utensils`,
                        title: "Прибори и салфетки",
                        price: 0,
                      }}
                    />
                  </div>
                )}
              </>
            )}

            {cart.length > 0 && (
              <div className="sticky-cart-footer">
                <a href="/cart" className="btn-success">
                  Поръчай
                </a>

                <div className="cart-value">
                  {cart_total < 20 && (
                    <div className="minimum-notice">
                      Още {(20 - cart_total).toFixed(2)} лв. до 20 лв. за
                      минимална поръчка
                    </div>
                  )}

                  {cart_total >= 20 && (
                    <div className="grand-total">
                      {cart_total.toFixed(2)} лв.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyCart;
