import React from "react";

const useCart = (initial = []) => {
  const [cart, setCart] = React.useState(initial);

  const add = (item, quantity) => {
    const { sku } = item;
    const idx = cart.findIndex((i) => i.sku === sku);

    if (idx !== -1) {
      const newCart = [
        ...cart.slice(0, idx),
        Object.assign({}, cart[idx], {
          quantity: cart[idx].quantity + quantity,
        }),
        ...cart.slice(idx + 1),
      ];
      setCart(newCart);

      return newCart;
    } else {
      const newCart = [...cart, Object.assign(item, { quantity })];
      setCart(newCart);

      return newCart;
    }
  };

  const remove = (sku) => {
    const idx = cart.findIndex((i) => i.sku === sku);
    const newCart = [...cart.slice(0, idx), ...cart.slice(idx + 1)];

    setCart(newCart);

    return newCart;
  };

  const update = (sku, attrs) => {
    const idx = cart.findIndex((i) => i.sku === sku);
    const newCart = [
      ...cart.slice(0, idx),
      Object.assign({}, cart[idx], attrs),
      ...cart.slice(idx + 1),
    ];

    setCart(newCart);

    return newCart;
  };

  const clear = () => setCart([]);

  return {
    cart,
    add,
    update,
    remove,
    clear,
  };
};

export default useCart;
