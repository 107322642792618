import React from 'react';
import PhoneInput from 'react-phone-number-input'

const PhoneInputWithIcon = ({ label, error, country = 'BG', value, onChange }) => (
  <div className={`form-group phone-input-wrapper ${error ? 'has-error' : ''}`}>
    <label htmlFor="">{label} <span className="required">*</span></label>

    <PhoneInput
      country={country}
      value={value}
      onChange={phone => onChange(phone)}
    />

    {error && <span className="help-block">{error}</span>}
  </div>
);

PhoneInputWithIcon.defaultProps = { value: '' };

export default PhoneInputWithIcon;
