import React from "react";
import PropTypes from "prop-types";
import { Container, Img, List, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const AccentNavigationWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="accent-nav">
      <div className="container">
        <nav className="row">
          {list.map((item, idx) => (
            <div className="col-3" key={idx}>
              <a href={item.link} className="nav-item">
                <Img src={item.image} variant="thumb" />
                <h6 className="title">{item.title}</h6>
              </a>
            </div>
          ))}
        </nav>
      </div>
    </div>
  </Container>
);

AccentNavigationWidget.title = "Accent Navigation";
AccentNavigationWidget.category = "General";

AccentNavigationWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

AccentNavigationWidget.defaults = {
  list: [
    {
      id: 1,
      title: "Just 2 minutes away from Main street",
      link: "#",
      image: "http://placehold.it/72x72",
    },
    {
      id: 2,
      title: "Main dish prices between € 5 - € 10",
      link: "#",
      image: "http://placehold.it/72x72",
    },
    {
      id: 3,
      title: "English-speaking staff and management",
      link: "#",
      image: "http://placehold.it/72x72",
    },
    {
      id: 4,
      title: "Plenty of vegetarian and vegan options",
      link: "#",
      image: "http://placehold.it/72x72",
    },
  ],
  containerSettings: { theme: "blue" },
};

const NavigationItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

NavigationItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

AccentNavigationWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      label="Navigation Items"
      listItem={(settings, onChangeItem) => (
        <NavigationItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        title: "",
        link: "#",
        image: "http://placehold.it/72x72",
      }}
    />
  </div>
);

export default AccentNavigationWidget;
