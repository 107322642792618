const getDataLayer = (cartSize, cartValue) => {
  return `
  <script>
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      cartSize: ${cartSize},
      cartValue: ${cartValue}
    });
  </script>
  `;
};

export default getDataLayer;
