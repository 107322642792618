import React from 'react';

const Textarea = ({ type, placeholder, label, name, error, value, onChange }) => (
  <div className={`form-group ${error ? 'has-error' : ''}`}>
    {label && <label className="control-label">{label}</label>}

    <textarea
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      rows="8"
      cols="80"
      className="form-control"
      onChange={(e) => onChange(e.target.value, e)}
    />

    {error && <span className="help-block">{error}</span>}
  </div>
);

Textarea.defaultProps = {
  type: 'text',
  placeholder: '',
  value: '',
};

export default Textarea;
