import React from "react";
import { useCookieCart, CartContext } from "../raketa-cart";
import SiteHeader from "./SiteHeader";

const Layout = ({ page_context, children }) => {
  const cart = useCookieCart();

  return (
    <>
      <CartContext.Provider value={cart}>
        <SiteHeader page_context={page_context} />

        {children}
      </CartContext.Provider>
    </>
  );
};

export default Layout;
