import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";
import Button from "../frontend/Button";

const variantType = {
  shifted: "shift-2",
  centered: "shift-2",
  contained: "col-8",
};

const LeadImageWidget = ({
  image,
  title,
  description,
  variant,
  overlay,
  buttonOne,
  buttonTwo,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div
      className="lead-image"
      style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
    >
      <div
        className="overlay"
        style={{ backgroundColor: `rgba(0, 0, 0, ${overlay})` }}
      ></div>

      <div className="container">
        <div className="row">
          <div className={variantType[variant]}>
            <div
              className={`text ${variant === "centered" ? "text-center" : ""}`}
            >
              <h1 className="title">{title}</h1>

              <div className="description">
                <p>{description}</p>
              </div>

              {((buttonOne.link !== "" && buttonOne.label !== "") ||
                (buttonTwo.link !== "" && buttonTwo.label !== "")) && (
                <div className="button-wrapper">
                  {buttonOne && buttonOne.label !== "" && (
                    <Button settings={buttonOne} />
                  )}
                  {buttonTwo && buttonTwo.label !== "" && (
                    <Button settings={buttonTwo} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

LeadImageWidget.defaultProps = {
  buttonOne: {},
  buttonTwo: {},
};

LeadImageWidget.title = "Lead Image";
LeadImageWidget.category = "Media";

LeadImageWidget.propTypes = {
  image: PropTypes.any.isRequired,
  overlay: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  buttonOne: PropTypes.object,
  buttonTwo: PropTypes.object,
  containerSettings: PropTypes.object.isRequired,
};

LeadImageWidget.defaults = {
  image: "http://placehold.it/1920x640",
  title: "Летни ястия - най-добрите храни за летния сезон",
  description:
    "Ела и открий палитра от вкусове, която ще те отведе на вълнуващо пътешествие по света. Ресторант Smokini съчетава вдъхновяващи ястия от международната кухня с характерни български рецепти. Очакваме те в един от най-хубавите ресторанти в Пловдив!😍",
  variant: "shifted",
  overlay: 0.2,
  buttonOne: {
    label: "",
    link: "",
    id: "",
    type: "primary",
    target: "_self",
    follow_link: "follow",
  },
  buttonTwo: {
    label: "",
    link: "",
    id: "",
    type: "secondary",
    target: "_self",
    follow_link: "follow",
  },
  containerSettings: { theme: "dark" },
};

LeadImageWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["shifted", "Shifted"],
      ["contained", "Contained"],
      ["centered", "Centered"],
    ],
  },
  overlay: {
    type: "select",
    options: [
      [0, "No Overlay"],
      [0.1, "10%"],
      [0.2, "20%"],
      [0.3, "30%"],
      [0.4, "40%"],
      [0.5, "50%"],
      [0.6, "60%"],
      [0.7, "70%"],
      [0.8, "80%"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "textarea" },
  buttonOne: { type: "button" },
  buttonTwo: { type: "button" },
};

export default LeadImageWidget;
