import React, { useState } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Container, Img, List } from "@raketa-cms/raketa-cms";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";

const GallerySliderWidget = ({ containerSettings, list, initialSlide }) => {
  const [swiper, updateSwiper] = useState(null);
  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const [lighboxOpen, setLightboxOpen] = useState(false);
  const galleryImages = list.map((item) => {
    return { src: imageSrc(item.image, "lead") };
  });

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  return (
    <Container settings={containerSettings} className="gallery-slider">
      <div className="container">
        <ModalGateway>
          {lighboxOpen ? (
            <Modal onClose={() => setLightboxOpen(false)}>
              <Carousel views={galleryImages} currentIndex={currentImageIdx} />
            </Modal>
          ) : null}
        </ModalGateway>

        <Swiper
          getSwiper={updateSwiper}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          keyboard={true}
          paginationClickable
          slidesPerView={3}
          spaceBetween={30}
          slidesPerGroup={3}
          slidesPerColumn={2}
          speed={500}
          initialSlide={initialSlide}
          breakpoints={{
            939: {
              slidesPerView: 2,
              spaceBetween: 30,
              paginationClickable: true,
              slidesPerGroup: 2,
              slidesPerColumn: 1,
            },

            600: {
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              slidesPerColumn: 1,
              paginationClickable: true,
            },
          }}
        >
          {list.map((slide, idx) => (
            <a
              href={slide.link}
              key={idx}
              onClick={() => {
                setLightboxOpen(true);
                setCurrentImageIdx(idx);
              }}
            >
              <Img src={slide.image} variant="article_thumb" />
            </a>
          ))}
        </Swiper>

        <button
          onClick={goNext}
          className="swiper-button-next icon-arrow-right"
        />
        <button
          onClick={goPrev}
          className="swiper-button-prev icon-arrow-left"
        />
      </div>
    </Container>
  );
};

GallerySliderWidget.defaultProps = {
  initialSlide: 0,
};

GallerySliderWidget.title = "Gallery Slider";
GallerySliderWidget.category = "Media";

GallerySliderWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

GallerySliderWidget.defaults = {
  list: [
    { id: 1, image: "http://placehold.it/360x240" },
    { id: 2, image: "http://placehold.it/360x240" },
    { id: 3, image: "http://placehold.it/360x240" },
    { id: 4, image: "http://placehold.it/360x240" },
    { id: 5, image: "http://placehold.it/360x240" },
    { id: 6, image: "http://placehold.it/360x240" },
    { id: 7, image: "http://placehold.it/360x240" },
    { id: 8, image: "http://placehold.it/360x240" },
    { id: 9, image: "http://placehold.it/360x240" },
    { id: 10, image: "http://placehold.it/360x240" },
  ],
  containerSettings: { theme: "white" },
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
  </div>
);

SlideItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

GallerySliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      label="Gallery items"
      listItem={(settings, onChangeItem) => (
        <SlideItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      template={{ image: "http://placehold.it/360x240" }}
      primaryField=""
    />
  </div>
);

export default GallerySliderWidget;
