import React from "react";
import PropTypes from "prop-types";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const SingleImageWidget = ({
  variant,
  image,
  description,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="row">
        <figure
          className={`single-image ${
            variant === "normal" ? "col-12" : "shift-2"
          }`}
        >
          <Img src={image} variant="image" />
        </figure>
      </div>
    </div>
  </Container>
);

SingleImageWidget.title = "Image";
SingleImageWidget.category = "Media";

SingleImageWidget.propTypes = {
  variant: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

SingleImageWidget.defaults = {
  variant: "normal",
  image: "http://placehold.it/980x735",
  containerSettings: { theme: "light" },
};

SingleImageWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["normal", "Normal"],
      ["shifted", "Shifted"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
};

export default SingleImageWidget;
