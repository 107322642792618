import React from "react";
import { useForm } from "react-hook-form";
import Input from "./Input";
import Select from "./Select";
import TypeSelector from "./TypeSelector";
import CheckoutTable from "./CheckoutTable";
import OrderItems from "./OrderItems";
import Loading from "./Loading";
import { CartContext } from "../raketa-cart";

const rand = () => Math.random().toString(36).substring(7).substring(0, 4);

const PickupForm = ({ time_slots, types, type, onChangeType }) => {
  const today = new Date();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      data: {
        order_id: `${rand().toUpperCase()}-${today.getHours()}${today.getMinutes()}`,
      },
    },
  });
  const { clear } = React.useContext(CartContext);

  const onSubmit = (values) => {
    fetch("https://forms.raketa.cloud/submit/k4WhLOFGLl ", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        const { form } = data;
        document.querySelector(".loader-container").classList.remove("hide");
        clear();

        window.location.href = form.success_location;
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  return (
    <form className="checkout-form" onSubmit={handleSubmit(onSubmit)}>
      <Loading />

      <div className="black-bg spacing-bottom-medium">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="spacing-bottom-small">
                <h5>Метод на получаване</h5>

                <TypeSelector
                  types={types}
                  value={type}
                  onChange={onChangeType}
                />
              </div>

              <Input r={register} type="hidden" name="data[order_id]" />

              <div className="spacing-bottom-small">
                <h5>Твоите контакти</h5>

                <Input
                  r={register}
                  name="data[name]"
                  label="Имена"
                  placeholder="Хари Потър"
                  required
                />

                <Input
                  r={register}
                  name="data[phone]"
                  label="Телефон, на който да потвърдим"
                  placeholder="088×××××××"
                  required
                />

                <Input
                  r={register}
                  type="email"
                  name="data[email]"
                  label="Email"
                  placeholder="harry@hogwarts.magi"
                  required
                />
              </div>

              <div className="spacing-bottom-small">
                <h5>Кога да бъде готово?</h5>

                <Select r={register} name="data[when]" required>
                  {time_slots.slots.map((slot, idx) => (
                    <option key={slot} value={slot}>
                      {idx === 0 ? "Най-скоро, " : ""}
                      {slot}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="spacing-bottom-small">
                <h4>Бележка към поръчката</h4>
                <div className="form-group">
                  <textarea
                    ref={register}
                    name="data[order_notes]"
                    className="form-control"
                    placeholder="Желаете сосът да е отделно или пък салатата разбъркана?"
                  />
                </div>
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-6">
              <div className="order-pannel">
                <h4>Вземи от ресторанта</h4>
                <p>
                  Възползвай се от 10% отстъпка от всички ястия и напитки като
                  поръчаш и вземеш от ресторанта.
                </p>

                <CheckoutTable type={type} discount={10} />

                <OrderItems r={register} discount={10} />

                <input
                  ref={register}
                  type="hidden"
                  name="data[order_type]"
                  value={type}
                />

                <div className="spacing-top-small">
                  <button type="submit" className="btn-success lg">
                    Поръчай
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PickupForm;
