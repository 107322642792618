import React from "react";

const Select = ({
  name,
  r,
  label,
  placeholder,
  children,
  wrapperProps = {},
  ...props
}) => (
  <div className="form-group" {...wrapperProps}>
    {label && <label className="control-label">{label}</label>}

    <select ref={r} name={name} className="form-control" {...props}>
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </select>
  </div>
);

export default Select;
