import React from "react";
import { CartContext } from "../raketa-cart";
import CartTable from "./CartTable";

const MINIMUM_PRICE = 20;

const CartPreview = () => {
  const { cart } = React.useContext(CartContext);

  const cart_total = cart.reduce(
    (acc, i) => acc + parseFloat(i.price) * i.quantity,
    0
  );

  return (
    <>
      {cart.length === 0 && (
        <div>
          <p>Няма избрани продукти. </p>
          <a href="/menu" className="btn-primary-alt">
            Към менюто
          </a>
        </div>
      )}

      {cart.length > 0 && (
        <>
          <CartTable type="delivery" />

          <div className="text-right">
            <a
              href="/checkout"
              className={`btn-success lg ${
                cart_total < MINIMUM_PRICE ? "disabled" : ""
              }`}
            >
              Продължи
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default CartPreview;
