import React from "react";

const DEFAULT_DAYS = [
  { day: "monday", ord: 1, from: "10:00", to: "20:00", active: true },
  { day: "tuesday", ord: 2, from: "10:00", to: "20:00", active: true },
  { day: "wednesday", ord: 3, from: "10:00", to: "20:00", active: true },
  { day: "thursday", ord: 4, from: "10:00", to: "20:00", active: true },
  { day: "friday", ord: 5, from: "10:00", to: "20:00", active: true },
  { day: "saturday", ord: 6, from: "10:00", to: "20:00", active: false },
  { day: "sunday", ord: 0, from: "10:00", to: "20:00", active: false },
];

const HOURS = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const formatWeekDays = (items) => {
  if (items.length === 0) {
    return DEFAULT_DAYS;
  }

  return [...items];
};

const DayItem = ({ weekDay, i18n, onChange }) => {
  const { day, from, to, active } = weekDay;

  const onUpdate = (key, value) => {
    onChange({ ...weekDay, ...{ [key]: value } });
  };

  return (
    <tr>
      <td>
        <div className="day-picker">
          <input
            type="checkbox"
            checked={active}
            id={day}
            onChange={(e) => onUpdate("active", e.target.checked)}
          />

          <label htmlFor={day}>{i18n[day]}</label>
        </div>
      </td>

      <td>
        <select
          value={from}
          onChange={(e) => onUpdate("from", e.target.value)}
          disabled={!active}
        >
          {HOURS.map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </select>
      </td>

      <td>
        <select
          value={to}
          onChange={(e) => onUpdate("to", e.target.value)}
          disabled={!active}
        >
          {HOURS.map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </select>
      </td>
    </tr>
  );
};

const updateList = (list, item) => {
  const idx = list.findIndex((current) => current.ord === item.ord);

  return [...list.slice(0, idx), { ...item }, ...list.slice(idx + 1)];
};

const WorkingHoursEditor = ({ items, name, i18n }) => {
  const [weekDays, setWeekDays] = React.useState(formatWeekDays(items));

  return (
    <div className="working-hours">
      <table>
        <thead>
          <tr>
            <th>{i18n.day}</th>
            <th>{i18n.from}</th>
            <th>{i18n.to}</th>
          </tr>
        </thead>
        <tbody>
          {weekDays.map((weekDay) => (
            <DayItem
              i18n={i18n}
              key={weekDay.ord}
              weekDay={weekDay}
              onChange={(newWeekDay) =>
                setWeekDays(updateList(weekDays, newWeekDay))
              }
            />
          ))}
        </tbody>
      </table>

      <input type="hidden" name={name} value={JSON.stringify(weekDays)} />
    </div>
  );
};

export default WorkingHoursEditor;
