import React from "react";
import PropTypes from "prop-types";
import { Container, Img, List, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const LogoStripWidget = ({ title, list, containerSettings }) => (
  <Container className="logo-strip" settings={containerSettings}>
    <h3>{title}</h3>

    <div className="container logos-wrapper">
      {list.map((item, idx) => (
        <React.Fragment key={idx}>
          {item.link ? (
            <a href={item.link} className="logo-wrapper">
              <Img src={item.logo} variant="original" />
            </a>
          ) : (
            <div className="logo-wrapper">
              <Img src={item.logo} variant="original" />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </Container>
);

LogoStripWidget.title = "Logo Strip";
LogoStripWidget.category = "Content";

LogoStripWidget.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

LogoStripWidget.defaults = {
  title: "Част от нашите клиенти",
  list: [
    { id: 1, link: "#", logo: "http://placehold.it/190x190" },
    { id: 2, link: "#", logo: "http://placehold.it/190x190" },
    { id: 3, link: "#", logo: "http://placehold.it/190x190" },
    { id: 4, link: "#", logo: "http://placehold.it/190x190" },
    { id: 5, link: "#", logo: "http://placehold.it/190x190" },
    { id: 6, link: "#", logo: "http://placehold.it/190x190" },
    { id: 7, link: "#", logo: "http://placehold.it/190x190" },
  ],
  containerSettings: { theme: "light" },
};

const MediaStripListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Logo"
      onChange={(value) => onChangeItem("logo", value)}
      value={settings.logo}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

MediaStripListItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

LogoStripWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Title"
      onChange={(value) => onChange("title", value)}
      value={settings.title}
    />

    <List
      label="Logos"
      listItem={(settings, onChangeItem) => (
        <MediaStripListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="link"
      template={{ link: "#", image: "http://placehold.it/190x190" }}
    />
  </div>
);

export default LogoStripWidget;
