import React from "react";

const LanguageContext = React.createContext();

const LanguageProvider = ({ children, language }) => (
  <LanguageContext.Provider value={language}>
    {children}
  </LanguageContext.Provider>
);

const LanguageConsumer = LanguageContext.Consumer;

export { LanguageProvider, LanguageConsumer };
