import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import Recaptcha from "react-google-invisible-recaptcha";

import httpPost from "../utils/Http";
import InputWithIcon from "../utils/InputWithIcon";
import DateInput from "../utils/DateInput";
import PhoneInputWithIcon from "../utils/PhoneInputWithIcon";

import dayjs from "dayjs";

class EventReservationFormWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      date: new Date(),
      name: "",
      company: "",
      email: "",
      hour: "",
      seats: "",
      budget: "",
      additionalInfo: "",
      drinks: [],
      meals: [],
      errors: {},
      buttonDisabled: false,
      sent: false,
      errored: false,
    };

    this.onRecaptchaResolved = this.onRecaptchaResolved.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.collectErrors = this.collectErrors.bind(this);
  }

  collectErrors() {
    const state = this.state;
    const errors = {};
    const errorMessages = {
      name: this.props.nameErrorMessage,
      company: this.props.companyErrorMessage,
      phone: this.props.phoneErrorMessage,
      email: this.props.emailErrorMessage,
      date: this.props.dateErrorMessage,
      hour: this.props.hourErrorMessage,
      seats: this.props.seatsErrorMessage,
      budget: this.props.budgetErrorMessage,
    };

    ["name", "company", "phone", "email", "hour", "seats", "budget"].forEach(
      function (key) {
        if (state[key].trim() === "") errors[key] = errorMessages[key];
      }
    );

    if (dayjs(state["date"]).format("YYYY-MM-DD") === "")
      errors["date"] = errorMessages["date"];

    return errors;
  }

  onRecaptchaResolved() {
    const {
      name,
      email,
      phone,
      company,
      date,
      hour,
      seats,
      budget,
      drinks,
      meals,
      additionalInfo,
    } = this.state;
    const captcha_value = this.captcha.getResponse();

    const params = {
      reservation: {
        name,
        email,
        phone,
        company,
        hour,
        seats,
        budget,
        drinks,
        meals,
      },
    };

    params["reservation"]["date"] = dayjs(date).format("YYYY-MM-DD");
    params["reservation"]["captcha_value"] = captcha_value;
    params["reservation"]["additional_info"] = additionalInfo;

    httpPost("/reservations", params)
      .then((resp) => {
        if (resp.ok) {
          this.setState({ sent: true });
        } else {
          this.setState({ sent: true, errored: true });
        }
      })
      .catch(() => {
        this.setState({ sent: true, errored: true });
      });
  }

  onSubmit(e) {
    e.preventDefault();

    const errors = this.collectErrors();

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ buttonDisabled: true });
      this.captcha.execute();
    } else {
      this.captcha.reset();
    }
  }

  addCheckBoxValue(checkBoxGroup, value) {
    const currentGroup = this.state[checkBoxGroup];
    const newValues = [...currentGroup, value];

    this.setState({ [checkBoxGroup]: newValues });
  }

  removeCheckBoxValue(checkBoxGroup, item) {
    const currentGroup = this.state[checkBoxGroup];

    const itemIdx = currentGroup.indexOf(item);
    const newItems = [
      ...currentGroup.slice(0, itemIdx),
      ...currentGroup.slice(itemIdx + 1),
    ];

    this.setState({ [checkBoxGroup]: newItems });
  }

  handleCheckBoxClick(checkBoxGroup, value) {
    this.state[checkBoxGroup].includes(value)
      ? this.removeCheckBoxValue(checkBoxGroup, value)
      : this.addCheckBoxValue(checkBoxGroup, value);
  }

  render() {
    const {
      title,
      description,
      button,
      containerSettings,

      errorMessage,
      successMessage,
      successMessageBtnLabel,

      nameLabel,
      namePlaceholder,

      emailLabel,
      emailPlaceholder,

      companyLabel,
      companyPlaceholder,

      phoneLabel,

      dateLabel,

      hourLabel,
      hourPlaceholder,

      seatsLabel,
      seatsPlaceholder,

      budgetLabel,
      budgetPlaceholder,

      additionalInfoLabel,
      additionalInfoPlaceholder,

      eventAdditionalInfoLabel,
      reservationDataLabel,
      eventDrinksLabel,
      yourDataLabel,

      drinksWine,
      drinksWater,
      drinksBeer,
      drinksFizzy,
      drinksSpirit,

      eventMealsLabel,

      mealsSalad,
      mealsAppetizer,
      mealsMain,
      mealsDessert,
      mealsVegetarian,
      mealsVegan,
    } = this.props;
    const { sent, errored, errors, buttonDisabled } = this.state;

    return (
      <Container settings={containerSettings}>
        {sent && (
          <div className="event-reservation-form">
            <div className="success-message container">
              <div className="success-title">
                <h3 className="title">
                  {errored ? errorMessage : successMessage}
                </h3>
              </div>
              {successMessageBtnLabel && (
                <a href="/" className="btn-primary">
                  {successMessageBtnLabel}
                </a>
              )}
            </div>
          </div>
        )}

        {!sent && (
          <form
            className="event-reservation-form"
            onSubmit={(e) => this.onSubmit(e)}
          >
            <div className="container">
              <div className="row">
                <div className="shift-2">
                  <div className="text">
                    <h2>{title}</h2>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </div>

                  <div className="section-heading-wrapper">
                    <h4 className="section-heading">{yourDataLabel}</h4>
                  </div>

                  <div className="form-section">
                    <div className="form-row">
                      <div className="half-col">
                        <InputWithIcon
                          label={nameLabel}
                          type="text"
                          placeholder={namePlaceholder}
                          value={this.state.name}
                          error={"name" in errors ? errors["name"] : false}
                          iconType="icon-name"
                          onChange={(value) => this.setState({ name: value })}
                        />
                      </div>

                      <div className="half-col">
                        <InputWithIcon
                          label={companyLabel}
                          type="text"
                          placeholder={companyPlaceholder}
                          value={this.state.company}
                          error={
                            "company" in errors ? errors["company"] : false
                          }
                          iconType="icon-company"
                          onChange={(value) =>
                            this.setState({ company: value })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="half-col">
                        <PhoneInputWithIcon
                          label={phoneLabel}
                          value={this.state.phone}
                          error={"phone" in errors ? errors["phone"] : false}
                          onChange={(value) => this.setState({ phone: value })}
                        />
                      </div>

                      <div className="half-col">
                        <InputWithIcon
                          label={emailLabel}
                          type="text"
                          placeholder={emailPlaceholder}
                          value={this.state.email}
                          error={"email" in errors ? errors["email"] : false}
                          iconType="icon-email"
                          onChange={(value) => this.setState({ email: value })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="section-heading-wrapper">
                    <h4 className="section-heading">{reservationDataLabel}</h4>
                  </div>

                  <div className="form-section">
                    <div className="form-row narow">
                      <div className="half-col">
                        <DateInput
                          label={dateLabel}
                          value={this.state.date}
                          error={"date" in errors ? errors["date"] : false}
                          onChange={(value) => this.setState({ date: value })}
                        />
                      </div>

                      <div className="half-col">
                        <InputWithIcon
                          label={hourLabel}
                          type="text"
                          placeholder={hourPlaceholder}
                          value={this.state.hour}
                          iconType="icon-hour"
                          error={"hour" in errors ? errors["hour"] : false}
                          onChange={(value) => this.setState({ hour: value })}
                        />
                      </div>
                    </div>

                    <div className="form-row narow">
                      <div className="half-col">
                        <InputWithIcon
                          label={seatsLabel}
                          type="text"
                          placeholder={seatsPlaceholder}
                          value={this.state.seats}
                          error={"seats" in errors ? errors["seats"] : false}
                          iconType="icon-seats"
                          onChange={(value) => this.setState({ seats: value })}
                        />
                      </div>

                      <div className="half-col">
                        <InputWithIcon
                          label={budgetLabel}
                          type="text"
                          placeholder={budgetPlaceholder}
                          value={this.state.budget}
                          error={"budget" in errors ? errors["budget"] : false}
                          iconType="icon-budget"
                          onChange={(value) => this.setState({ budget: value })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="section-heading-wrapper">
                    <h4 className="section-heading">
                      {eventAdditionalInfoLabel}
                    </h4>
                  </div>

                  <div className="form-group">
                    <label htmlFor="">{eventDrinksLabel}</label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("drinks", drinksWine)
                        }
                      />

                      <div className="label-checkbox">{drinksWine}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("drinks", drinksWater)
                        }
                      />

                      <div className="label-checkbox">{drinksWater}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("drinks", drinksBeer)
                        }
                      />

                      <div className="label-checkbox">{drinksBeer}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("drinks", drinksFizzy)
                        }
                      />

                      <div className="label-checkbox">{drinksFizzy}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("drinks", drinksSpirit)
                        }
                      />

                      <div className="label-checkbox">{drinksSpirit}</div>
                    </label>
                  </div>

                  <div className="form-group">
                    <label htmlFor="">{eventMealsLabel}</label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("meals", mealsSalad)
                        }
                      />

                      <div className="label-checkbox">{mealsSalad}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("meals", mealsAppetizer)
                        }
                      />

                      <div className="label-checkbox">{mealsAppetizer}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("meals", mealsMain)
                        }
                      />

                      <div className="label-checkbox">{mealsMain}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("meals", mealsDessert)
                        }
                      />

                      <div className="label-checkbox">{mealsDessert}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("meals", mealsVegetarian)
                        }
                      />

                      <div className="label-checkbox">{mealsVegetarian}</div>
                    </label>

                    <label className="checkbox-button">
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          this.handleCheckBoxClick("meals", mealsVegan)
                        }
                      />

                      <div className="label-checkbox">{mealsVegan}</div>
                    </label>
                  </div>

                  <Recaptcha
                    ref={(el) => {
                      this.captcha = el;
                    }}
                    // TODO: The site key should be a configuration
                    sitekey="6LfjF28UAAAAADY7gZIt04qq2mdUAF-juqrZYzJT" // Smokini's key
                    // sitekey="6Ld6aW8UAAAAAAIvBvJvElOtoSST57Hvvp4B1LXj" // # Svetlio M's dev key
                    onResolved={this.onRecaptchaResolved}
                  />

                  <div className="form-group additional-info">
                    <label htmlFor="">{additionalInfoLabel}</label>
                    <textarea
                      className="form-control"
                      cols="30"
                      rows="5"
                      placeholder={additionalInfoPlaceholder}
                      onChange={(e) =>
                        this.setState({ additionalInfo: e.target.value })
                      }
                    />

                    <input
                      type="submit"
                      className="btn-primary-alt"
                      value={button}
                      disabled={buttonDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>

            <input
              type="hidden"
              name="event_reservations"
              value={JSON.stringify(this.state)}
            />
          </form>
        )}
      </Container>
    );
  }
}

EventReservationFormWidget.title = "Event Reservation Form";
EventReservationFormWidget.category = "Lead Generation";
EventReservationFormWidget.dialogSize = "large";

EventReservationFormWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,

  successMessage: PropTypes.string.isRequired,
  successMessageBtnLabel: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,

  nameLabel: PropTypes.string.isRequired,
  namePlaceholder: PropTypes.string.isRequired,
  nameErrorMessage: PropTypes.string.isRequired,

  emailLabel: PropTypes.string.isRequired,
  emailPlaceholder: PropTypes.string.isRequired,
  emailErrorMessage: PropTypes.string.isRequired,

  companyLabel: PropTypes.string.isRequired,
  companyPlaceholder: PropTypes.string.isRequired,
  companyErrorMessage: PropTypes.string.isRequired,

  phoneLabel: PropTypes.string.isRequired,
  phoneErrorMessage: PropTypes.string.isRequired,

  dateLabel: PropTypes.string.isRequired,
  dateErrorMessage: PropTypes.string.isRequired,

  hourLabel: PropTypes.string.isRequired,
  hourPlaceholder: PropTypes.string.isRequired,
  hourErrorMessage: PropTypes.string.isRequired,

  seatsLabel: PropTypes.string.isRequired,
  seatsPlaceholder: PropTypes.string.isRequired,
  seatsErrorMessage: PropTypes.string.isRequired,

  budgetLabel: PropTypes.string.isRequired,
  budgetPlaceholder: PropTypes.string.isRequired,
  budgetErrorMessage: PropTypes.string.isRequired,

  additionalInfoLabel: PropTypes.string.isRequired,
  additionalInfoPlaceholder: PropTypes.string.isRequired,

  eventAdditionalInfoLabel: PropTypes.string.isRequired,
  reservationDataLabel: PropTypes.string.isRequired,
  eventDrinksLabel: PropTypes.string.isRequired,
  yourDataLabel: PropTypes.string.isRequired,

  drinksWine: PropTypes.string.isRequired,
  drinksWater: PropTypes.string.isRequired,
  drinksBeer: PropTypes.string.isRequired,
  drinksFizzy: PropTypes.string.isRequired,
  drinksSpirit: PropTypes.string.isRequired,

  eventMealsLabel: PropTypes.string.isRequired,

  mealsSalad: PropTypes.string.isRequired,
  mealsAppetizer: PropTypes.string.isRequired,
  mealsMain: PropTypes.string.isRequired,
  mealsDessert: PropTypes.string.isRequired,
  mealsVegetarian: PropTypes.string.isRequired,
  mealsVegan: PropTypes.string.isRequired,

  containerSettings: PropTypes.object.isRequired,
};

EventReservationFormWidget.defaults = {
  title: "Организирайте лесно вашето събитие",
  button: "Изпрати",
  description:
    "<p>Опитай работата с професионалисти и никога повече няма да разчиташ на собствените си сили при организирането на комплексни хранения</p>",

  successMessage: "Благодаря, че изпратихте запитване",
  successMessageBtnLabel: "Към началната страница на сайта",
  errorMessage: "Техническа повреда. Моля опитайте по-късно",

  nameLabel: "Вашето име",
  namePlaceholder: "Вашето име",
  nameErrorMessage: "Полето е задължително",

  emailLabel: "E-mail адрес",
  emailPlaceholder: "Вашият електронен адрес",
  emailErrorMessage: "Полето е задължително",

  companyLabel: "име на вашата компания",
  companyPlaceholder: "Щипка сол",
  companyErrorMessage: "Полето е задължително",

  phoneLabel: "Вашият телефонен номер",
  phoneErrorMessage: "Полето е задължително",

  dateLabel: "За коя дата запазвате?",
  dateErrorMessage: "Полето е задължително",

  hourLabel: "За колко часа?",
  hourPlaceholder: "20:00",
  hourErrorMessage: "Полето е задължително",

  seatsLabel: "Колко места да запазим?",
  seatsPlaceholder: "5",
  seatsErrorMessage: "Полето е задължително",

  budgetLabel: "Какъв е бюджетът ви за събитието?",
  budgetPlaceholder: "500",
  budgetErrorMessage: "Полето е задължително",

  additionalInfoLabel:
    "Ако имате допълнителна информация можете да я запишете тук",
  additionalInfoPlaceholder: "",

  reservationDataLabel: "Данни за резервацията",
  eventAdditionalInfoLabel: "Допълнителна (незадължителна) информация",
  eventDrinksLabel: "Какви напитки очаквате на събитието?",
  yourDataLabel: "Вашите данни",

  drinksWine: "Вино",
  drinksWater: "Вода",
  drinksBeer: "Бира",
  drinksFizzy: "Безалкохолно",
  drinksSpirit: "Концентрат",

  eventMealsLabel: "Какви ястия искате да подготвим за гостите ви?",

  mealsSalad: "Салата",
  mealsAppetizer: "Предястие",
  mealsMain: "Основно ястие",
  mealsDessert: "Десерт",
  mealsVegetarian: "Вегетариански",
  mealsVegan: "Веган",

  containerSettings: { theme: "dark" },
};

EventReservationFormWidget.adminFields = {
  title: { type: "text" },
  description: { type: "custom", component: RichText },
  button: { type: "text" },

  successMessage: { type: "text" },
  successMessageBtnLabel: { type: "text" },
  errorMessage: { type: "text" },

  nameLabel: { type: "text" },
  namePlaceholder: { type: "text" },
  nameErrorMessage: { type: "text" },

  emailLabel: { type: "text" },
  emailPlaceholder: { type: "text" },
  emailErrorMessage: { type: "text" },

  companyLabel: { type: "text" },
  companyPlaceholder: { type: "text" },
  companyErrorMessage: { type: "text" },

  phoneLabel: { type: "text" },
  phoneErrorMessage: { type: "text" },

  dateLabel: { type: "text" },
  dateErrorMessage: { type: "text" },

  hourLabel: { type: "text" },
  hourPlaceholder: { type: "text" },
  hourErrorMessage: { type: "text" },

  seatsLabel: { type: "text" },
  seatsPlaceholder: { type: "text" },
  seatsErrorMessage: { type: "text" },

  budgetLabel: { type: "text" },
  budgetPlaceholder: { type: "text" },
  budgetErrorMessage: { type: "text" },

  additionalInfoLabel: { type: "text" },
  additionalInfoPlaceholder: { type: "text" },

  reservationDataLabel: { type: "text" },
  eventAdditionalInfoLabel: { type: "text" },
  eventDrinksLabel: { type: "text" },
  yourDataLabel: { type: "text" },

  drinksWine: { type: "text" },
  drinksWater: { type: "text" },
  drinksBeer: { type: "text" },
  drinksFizzy: { type: "text" },
  drinksSpirit: { type: "text" },

  eventMealsLabel: { type: "text" },

  mealsSalad: { type: "text" },
  mealsAppetizer: { type: "text" },
  mealsMain: { type: "text" },
  mealsDessert: { type: "text" },
  mealsVegetarian: { type: "text" },
  mealsVegan: { type: "text" },
};

export default EventReservationFormWidget;
