import React, { useState } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import {
  Container,
  Img,
  List,
  TextInput,
  SelectMenu,
} from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";

const socNetwork = (network) => {
  switch (network) {
    case "facebook":
      return "facebook";
    case "trip_advisor":
      return "tripadvisor";
    case "foursquare":
      return "foursquare";
    case "local_guides":
      return "local-guides";
    default:
      return "facebook";
  }
};

const TestimonialSliderWidget = ({ containerSettings, list, initialSlide }) => {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  return (
    <Container settings={containerSettings} className="testimonial-slider">
      <div className="container">
        <Swiper
          getSwiper={updateSwiper}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          keyboard={true}
          paginationClickable
          slidesPerView={1}
          spaceBetween={0}
          loop
          speed={500}
          initialSlide={initialSlide}
        >
          {list.map((slide, idx) => (
            <div className="row" key={idx}>
              <div className="col-8">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: slide.text }}
                />
                <h4 className="author">
                  <span className="author-name">
                    <span>{slide.name}</span>
                    <span className="base-font on">on</span>
                  </span>
                  <a
                    href={slide.link}
                    className={`network ${socNetwork(slide.network)}`}
                  ></a>
                </h4>
              </div>
            </div>
          ))}
        </Swiper>

        <button
          onClick={goNext}
          className="swiper-button-next icon-arrow-right"
        />
        <button
          onClick={goPrev}
          className="swiper-button-prev icon-arrow-left"
        />
      </div>
    </Container>
  );
};

TestimonialSliderWidget.defaultProps = {
  initialSlide: 0,
};

TestimonialSliderWidget.title = "Testimonial Slider";
TestimonialSliderWidget.category = "Content";

TestimonialSliderWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

TestimonialSliderWidget.defaults = {
  list: [
    {
      id: 1,
      text:
        "We found this restaurant via TripAdvisor and we were not disappointed. We chose to drink a Bulgarian white wine sauvignon blanc, it was recommended by waiter and was very refreshing with a lovely hint of elderflower. We chose a Bulgarian meat platter and a Bulgarian salad platter as sharing started. Both very good - see photo. For main course my parents had chicken with Brie and figs - lovely. I had today special of fresh tuna steak - yummy and seared to perfection.We finished off with krokant ice cream with figs - super - very large portion, and expresso which were delicious together with a local brandy.Go try - very good.",
      name: "Peter",
      network: "facebook",
      link: "#",
    },
    {
      id: 2,
      text:
        "We found this restaurant via TripAdvisor and we were not disappointed. We chose to drink a Bulgarian white wine sauvignon blanc, it was recommended by waiter and was very refreshing with a lovely hint of elderflower. We chose a Bulgarian meat platter and a Bulgarian salad platter as sharing started. Both very good - see photo. For main course my parents had chicken with Brie and figs - lovely. I had today special of fresh tuna steak - yummy and seared to perfection.We finished off with krokant ice cream with figs - super - very large portion, and expresso which were delicious together with a local brandy.Go try - very good.",
      name: "Jhon",
      network: "trip_advisor",
      link: "#",
    },
    {
      id: 3,
      text:
        "We found this restaurant via TripAdvisor and we were not disappointed. We chose to drink a Bulgarian white wine sauvignon blanc, it was recommended by waiter and was very refreshing with a lovely hint of elderflower. We chose a Bulgarian meat platter and a Bulgarian salad platter as sharing started. Both very good - see photo. For main course my parents had chicken with Brie and figs - lovely. I had today special of fresh tuna steak - yummy and seared to perfection.We finished off with krokant ice cream with figs - super - very large portion, and expresso which were delicious together with a local brandy.Go try - very good.",
      name: "Jhon",
      network: "foursquare",
      link: "#",
    },
    {
      id: 4,
      text:
        "We found this restaurant via TripAdvisor and we were not disappointed. We chose to drink a Bulgarian white wine sauvignon blanc, it was recommended by waiter and was very refreshing with a lovely hint of elderflower. We chose a Bulgarian meat platter and a Bulgarian salad platter as sharing started. Both very good - see photo. For main course my parents had chicken with Brie and figs - lovely. I had today special of fresh tuna steak - yummy and seared to perfection.We finished off with krokant ice cream with figs - super - very large portion, and expresso which were delicious together with a local brandy.Go try - very good.",
      name: "Jhon",
      network: "local_guides",
      link: "#",
    },
  ],
  containerSettings: { theme: "white" },
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <RichText
      label="Text"
      onChange={(value) => onChangeItem("text", value)}
      value={settings.text}
    />

    <TextInput
      label="Name"
      onChange={(value) => onChangeItem("name", value)}
      value={settings.name}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <SelectMenu
      label="Network"
      options={[
        ["facebook", "Facebook"],
        ["trip_advisor", "Trip Advisor"],
        ["foursquare", "Foursquare"],
        ["local_guides", "Local Guides"],
      ]}
      value={settings.network}
      onChange={(value) => onChangeItem("network", value)}
    />
  </div>
);

SlideItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

TestimonialSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      label="Testimonials"
      listItem={(settings, onChangeItem) => (
        <SlideItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="name"
      template={{
        text:
          "We found this restaurant via TripAdvisor and we were not disappointed. We chose to drink a Bulgarian white wine sauvignon blanc, it was recommended by waiter and was very refreshing with a lovely hint of elderflower. We chose a Bulgarian meat platter and a Bulgarian salad platter as sharing started. Both very good - see photo. For main course my parents had chicken with Brie and figs - lovely. I had today special of fresh tuna steak - yummy and seared to perfection.We finished off with krokant ice cream with figs - super - very large portion, and expresso which were delicious together with a local brandy.Go try - very good.",
        name: "Jhon",
        network: "local_guides",
        link: "#",
      }}
    />
  </div>
);

export default TestimonialSliderWidget;
