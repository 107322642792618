import React, { useState } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import { Container, Img, List, TextInput } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import formatPrice from "../formatPrice";
import { LanguageConsumer } from "../LanguageContext";

const MealsSliderWidget = ({ containerSettings, list, initialSlide }) => {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  return (
    <LanguageConsumer>
      {(language) => (
        <Container settings={containerSettings} className="thumbs-slider">
          <div className="container">
            <Swiper
              getSwiper={updateSwiper}
              pagination={{
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
              }}
              keyboard={true}
              paginationClickable
              loop
              speed={500}
              initialSlide={initialSlide}
              breakpoints={{
                601: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                  paginationClickable: true,
                  slidesPerGroup: 3,
                },

                600: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  slidesPerGroup: 1,
                  paginationClickable: true,
                },
              }}
            >
              {list.map((slide, idx) => (
                <div className="menu-card" key={idx}>
                  <a href={slide.link}>
                    <Img
                      src={slide.image}
                      alt={`${slide.title}
                    ${language === "bg" ? "снимка" : "photo"}`}
                      variant="article_thumb"
                    />
                  </a>

                  <div className="text">
                    <div className="title">
                      <h6 className="title-text">
                        <a href="#">{slide.title}</a>
                      </h6>
                      <div className="price">
                        {formatPrice(slide.price, language)}
                      </div>
                    </div>

                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: slide.description }}
                    />
                    <div className="card-footer">
                      <p className="serving-info">
                        {slide.grams} <span className="separator"></span>{" "}
                        {slide.calories}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Swiper>

            <button
              onClick={goNext}
              className="swiper-button-next icon-arrow-right"
            />
            <button
              onClick={goPrev}
              className="swiper-button-prev icon-arrow-left"
            />
          </div>
        </Container>
      )}
    </LanguageConsumer>
  );
};

MealsSliderWidget.contextTypes = {
  language: PropTypes.any,
};

MealsSliderWidget.defaultProps = {
  initialSlide: 0,
};

MealsSliderWidget.title = "Meals Slider";
MealsSliderWidget.category = "Content";
MealsSliderWidget.dialogSize = "large";

MealsSliderWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

MealsSliderWidget.defaults = {
  list: [
    {
      id: 1,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 2,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 3,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 4,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 5,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 6,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 7,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 8,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 9,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
  ],
  containerSettings: { theme: "dark" },
};

const SlideItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <TextInput
      label="Price"
      onChange={(value) => onChangeItem("price", value)}
      value={settings.price}
    />

    <TextInput
      label="Grams"
      onChange={(value) => onChangeItem("grams", value)}
      value={settings.grams}
    />

    <TextInput
      label="Calories"
      onChange={(value) => onChangeItem("calories", value)}
      value={settings.calories}
    />
  </div>
);

SlideItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

MealsSliderWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      label="Meals"
      listItem={(settings, onChangeItem) => (
        <SlideItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="link"
      template={{
        link: "#",
        image: "http://placehold.it/360x240",
        title: "Пилешко филе със смокини и течно Бри",
        price: "99.99",
        description:
          "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
        grams: "330 г",
        calories: "999 kcal",
      }}
    />
  </div>
);

export default MealsSliderWidget;
