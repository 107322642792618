import React from 'react';

class PopupNotice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    }
  }

  render() {
    const { isOpen } = this.state;
    const { announcementTitle, announcementDescription, announcementButtonLabel } = this.props;

    return (
      <div className={`popup-notice-wrapper ${!isOpen ? 'hide' : ''}`}>
        <div className="popup-notice">
          <div className="icon-error" />
          <h2>{announcementTitle}</h2>
          <p>{announcementDescription}</p>

          <a href="#" className="btn-primary-alt" onClick={(e) => { e.preventDefault(); this.setState({ isOpen: false }); }}>{announcementButtonLabel}</a>
        </div>
      </div>
    );
  }
}

export default PopupNotice;
