import React from "react";
import { PageBuilder } from "@raketa-cms/raketa-cms";
import {
  MediaManager,
  MediaManagerContext,
} from "@raketa-cms/raketa-image-picker";

import MenuProvider from "./MenuProvider";
import { LanguageProvider } from "./LanguageContext";
import LIBRARY from "./widgets";

const SPACINGS = [
  ["none", "None"],

  ["top-small", "Top Small"],
  ["top-medium", "Top Medium"],
  ["top-large", "Top Large"],

  ["both-small", "Both Small"],
  ["both-medium", "Both Medium"],
  ["both-large", "Both Large"],

  ["bottom-small", "Bottom Small"],
  ["bottom-medium", "Bottom Medium"],
  ["bottom-large", "Bottom Large"],
];

const THEMES = [
  ["none", "None"],
  ["light", "Light"],
  ["dark", "Dark"],
  ["white", "Pure White"],
  ["blue", "Blue"],
];

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    console.error(error, info);
  }
  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

const mediaManager = new MediaManager("/images/client/");

const AdminBuilder = ({
  page: defaultPage,
  host,
  save_url,
  back_url,
  lunchMenu,
  weeklyMenu,
  language,
}) => {
  const [dirty, setDirty] = React.useState(false);
  const [page, setPage] = React.useState(defaultPage);

  const handleChange = (page) => {
    setPage(page);
    setDirty(true);
  };

  const handleSave = (page) => {
    fetch(save_url, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        "content-type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ widgets: page.widgets }),
    })
      .then(() => {
        setDirty(false);
      })
      .catch((error) => {
        console.error(error);
        alert("An error occured saving this page. ");
      });
  };

  return (
    <ErrorBoundary>
      <MediaManagerContext.Provider value={mediaManager}>
        <div className="widgets-spacings-reset">
          <LanguageProvider language={language}>
            <MenuProvider lunchMenu={lunchMenu} weeklyMenu={weeklyMenu}>
              <PageBuilder
                host={host}
                dirty={dirty}
                library={LIBRARY}
                adminLibrary={LIBRARY}
                themes={THEMES}
                spacings={SPACINGS}
                page={page}
                onChange={handleChange}
                onSave={handleSave}
                onExit={() => (window.location.href = back_url)}
              />
            </MenuProvider>
          </LanguageProvider>
        </div>
      </MediaManagerContext.Provider>
    </ErrorBoundary>
  );
};

AdminBuilder.defaultProps = {
  host: "http://localhost:3000/",
};

export default AdminBuilder;
