const httpPost = (url, params = {}, opts = {}) => fetch(
  url,
  Object.assign({
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(params),
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      'Content-Type': 'application/json'
    },
  },
    opts
  ))

export default httpPost;
