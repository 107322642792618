import React from "react";
import PropTypes from "prop-types";
import jQuery from "jquery";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";

const bgColor = (color) => {
  switch (color) {
    case "purple":
      return "purple-bg";
    case "blue":
      return "blue-bg";
    case "red":
      return "red-bg";
    default:
      return "purple-bg";
  }
};

class SubscribeWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      sent: false,
      error: false,
      isSubmitEnabled: true,
    };
  }

  submitForm($form) {
    jQuery.ajax({
      type: $form.attr("method"),
      url: $form.attr("action"),
      data: $form.serialize(),
      cache: false,
      dataType: "jsonp",
      contentType: "application/json; charset=utf-8",
      error: (err) => {
        this.setState({ sent: true, error: true });
      },
      success: (data) => {
        if (data.result === "success") {
          this.setState({ sent: true });
        } else {
          console.error("Mailchimp Error: ", data.msg);
          this.setState({ sent: true, error: true });
        }
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const $form = jQuery(e.target);

    this.submitForm($form);
  }

  render() {
    const {
      image,
      title,
      description,
      button,
      backgroundColor,
      url,
      successMessage,
      errorMessage,
      checkboxText,
      containerSettings,
    } = this.props;
    const { email, sent, error, isSubmitEnabled } = this.state;

    return (
      <Container settings={containerSettings}>
        <div className="mobile-full-width-container">
          <div className="container">
            <div
              className={`subscribe border-radius-both ${bgColor(
                backgroundColor
              )}`}
            >
              <div className="row">
                <div className="shift-1">
                  <div className="row">
                    <div className="col-6">
                      <h3 className="title">{title}</h3>
                      <p className="description">{description}</p>

                      {!sent && (
                        <form
                          onSubmit={(e) => this.handleSubmit(e)}
                          action={url}
                          method="get"
                          id="mc-embedded-subscribe-form"
                          name="mc-embedded-subscribe-form"
                          className="validate"
                        >
                          <div className="form-group">
                            <input
                              type="email"
                              value=""
                              name="EMAIL"
                              className="email"
                              id="mce-EMAIL"
                              placeholder="Email"
                              required
                              value={email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </div>

                          <div
                            style={{ position: "absolute", left: "-5000px" }}
                            aria-hidden="true"
                          >
                            <input
                              type="text"
                              name="b_6262061244790355a02e683d1_4421e754b8"
                              tabIndex="-1"
                              value=""
                            />
                          </div>

                          <label className="checkbox-style">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                this.setState({
                                  isSubmitEnabled: !e.target.checked,
                                })
                              }
                            />

                            <div
                              className="label-checkbox"
                              dangerouslySetInnerHTML={{ __html: checkboxText }}
                            />
                          </label>

                          <input
                            type="submit"
                            value={button}
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="btn-primary"
                            disabled={isSubmitEnabled}
                          />

                          <div id="subscribe-result"></div>
                        </form>
                      )}

                      {sent && <p>{error ? errorMessage : successMessage}</p>}
                    </div>

                    <div
                      className="col-6 image-bg"
                      style={{
                        backgroundImage: `url(${imageSrc(image, "original")})`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

SubscribeWidget.title = "Subscribe";
SubscribeWidget.category = "Lead Generation";

SubscribeWidget.defaults = {
  url: "",
  title: "Знай обедното ни меню преди всички",
  description:
    "Запиши се в нашия мейл лист и ще ти изпращаме обедни вкусотии всеки делничен ден. Вече лесно можеш да избереш какво да обядваш.",
  successMessage: "",
  errorMessage: "",
  button: "Запиши ме за бюлетина",
  backgroundColor: "purple",
  image: "http://placehold.it/460x300",
  checkboxText: "Съгласен съм с <общите условия>",
  containerSettings: { theme: "light" },
};

SubscribeWidget.adminFields = {
  url: { type: "text" },
  title: { type: "text" },
  description: { type: "textarea" },
  successMessage: { type: "textarea" },
  errorMessage: { type: "textarea" },
  button: { type: "text" },
  checkboxText: { type: "custom", component: RichText },
  backgroundColor: {
    type: "select",
    options: [
      ["purple", "Purple"],
      ["blue", "Blue"],
      ["red", "Red"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
};

export default SubscribeWidget;
