import React from "react";
import Layout from "../frontend/Layout";
import Product from "./Product";

const rand = () => Math.random().toString(36).substring(7);

const Products = ({
  page_context,
  current_category,
  products,
  food_categories,
  menu_food,
  menu_drinks,
}) => {
  return (
    <Layout page_context={page_context}>
      <div className="lead-image dark-bg menu-header">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <div className="text">
                <h1 className="title">Вкусно. И доставено до теб.</h1>
                <div className="description">
                  <p>
                    Ресторант Smokini вече прави и доставка на храна до всеки
                    адрес в Пловдив. Избери салата, предястие, основно, десерт,
                    напитка или вино и можеш да го вземеш от ресторанта или ние
                    да го доставим до твоят дом или офис в Пловдив за по-малко
                    от час.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-cards spacing-top-large spacing-bottom-small dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <div className="spacing-bottom-small">
                <h4>{menu_food.name}</h4>

                <ul className="product-categories-list">
                  <li>
                    <a
                      href="/menu"
                      className={
                        current_category === "all-food" ? "current" : ""
                      }
                    >
                      Всички ястия
                    </a>
                  </li>

                  {menu_food.items.map((category) => (
                    <li key={category.id}>
                      <a
                        href={category.link}
                        className={
                          category.link.replace("/categories/", "") ===
                          current_category
                            ? "current"
                            : ""
                        }
                      >
                        {category.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="spacing-bottom-small">
                <h4>{menu_drinks.name}</h4>

                <ul className="product-categories-list">
                  <li>
                    <a
                      href="/drinks"
                      className={
                        current_category === "all-drinks" ? "current" : ""
                      }
                    >
                      Всички напитки
                    </a>
                  </li>

                  {menu_drinks.items.map((category) => (
                    <li key={category.id}>
                      <a
                        href={category.link}
                        className={
                          category.link.replace("/categories/", "") ===
                          current_category
                            ? "current"
                            : ""
                        }
                      >
                        {category.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-9">
              <div className="menu-cards-wrapper">
                {products.length > 0 &&
                  products.map((product) => (
                    <div key={product.id} className="card-col">
                      <Product product={product} page_context={page_context} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
