import React from "react";

const StepInput = ({ value, onChange }) => (
  <div className="step-input">
    <button
      type="button"
      className={value === 1 ? "step-delete" : "step-minus"}
      onClick={() => onChange(value === 0 ? 0 : value - 1)}
    >
      -
    </button>
    <span className="value">{value}</span>
    <button
      type="button"
      className="step-plus"
      onClick={() => onChange(value + 1)}
    >
      +
    </button>
  </div>
);

export default StepInput;
