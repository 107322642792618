import React from "react";
import PropTypes from "prop-types";
import { Container, List, TextInput } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker, imageSrc } from "@raketa-cms/raketa-image-picker";

import Swiper from "react-id-swiper";

const AccentImageWidget = ({
  list,
  containerSettings,
  imageDesktop,
  imageMobile,
  title,
  initialSlide,
}) => (
  <Container settings={containerSettings}>
    <div className="accent-image">
      <div
        className="desktop-image"
        style={{
          backgroundImage: `url(${imageSrc(imageDesktop, "lead")})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-6 accent-image-col"></div>

            <div className="col-6 accent-image-col">
              <div className="text">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />

                {list.map((item, idx) => (
                  <div key={item.id}>
                    <h6>{item.title}</h6>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="mobile-accent"
        style={{
          backgroundImage: `url(${imageSrc(imageMobile, "image")})`,
        }}
      >
        <div className="container">
          <div className="title" dangerouslySetInnerHTML={{ __html: title }} />
        </div>

        <Swiper
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          keyboard={true}
          paginationClickable
          slidesPerView={3}
          spaceBetween={30}
          loop
          slidesPerGroup={3}
          speed={500}
          initialSlide={initialSlide}
          breakpoints={{
            939: {
              slidesPerView: 2,
              spaceBetween: 30,
              paginationClickable: true,
              slidesPerGroup: 2,
            },

            600: {
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 1,
              paginationClickable: true,
            },
          }}
        >
          {list.map((item, idx) => (
            <div className="text container" key={item.id}>
              <h6>{item.title}</h6>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  </Container>
);

AccentImageWidget.defaultProps = {
  initialSlide: 0,
};

AccentImageWidget.title = "Accent Image";
AccentImageWidget.category = "Media";

AccentImageWidget.propTypes = {
  list: PropTypes.array.isRequired,
  imageDesktop: PropTypes.any.isRequired,
  imageMobile: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

AccentImageWidget.defaults = {
  list: [
    {
      id: 1,
      title: "РАЗЛИЧНИ ВКУСОВЕ",
      description:
        "<p>Обещаваме да експериментираме и търсим най-интересните предложения от краищата на света, за да ти ги поднесем на обяд или вечеря.</p>",
    },
    {
      id: 2,
      title: "КАЧЕСТВЕНИ СЪСТАВКИ",
      description:
        "<p>Вярваме, че вкусът е подчинен не само на упоритата работа, но и на качествените компоненти. За това работим само с най-добрите продукти на пазара.</p>",
    },
    {
      id: 3,
      title: "ПРИЯТНА АТМОСФЕРА",
      description:
        "<p>От усмивка на вратата, през приятен интериор, до бързо обслужване - ние винаги ще се стараем да ти предложим най-доброто преживяване.</p>",
    },
  ],
  title: "<h2>Нашето обещание:</h2> <h3>Винаги вкусна кухня</h3>",
  imageDesktop: "http://placehold.it/1920x880",
  imageMobile: "http://placehold.it/660x380",
  containerSettings: {},
};

const AccentImageItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

AccentImageItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

AccentImageWidget.adminFields = (items, onChange, settings) => (
  <div>
    <RichText
      label="Title"
      onChange={(value) => onChange("title", value)}
      value={settings.title}
    />

    <ImagePicker
      label="Desktop Image"
      onChange={(value) => onChange("imageDesktop", value)}
      value={settings.imageDesktop}
    />

    <ImagePicker
      label="Mobile Image"
      onChange={(value) => onChange("imageMobile", value)}
      value={settings.imageMobile}
    />

    <List
      label="Accent Items"
      listItem={(settings, onChangeItem) => (
        <AccentImageItem settings={settings} onChangeItem={onChangeItem} />
      )}
      template={{ title: "", description: "" }}
      onChangeList={onChange}
      items={items}
      primaryField="title"
    />
  </div>
);

export default AccentImageWidget;
