import React from "react";
import { CartContext } from "../raketa-cart";

const formatItems = (cart, discount = 0) => {
  const items = cart.map((item) => {
    const price = parseFloat(item.price).toFixed(2);
    const total = price * item.quantity;

    return `<strong>${item.quantity}x</strong> ${
      item.title
    } → <strong>${total.toFixed(2)} лв.</strong><br />`;
  });

  const cart_total = cart.reduce(
    (acc, i) => acc + parseFloat(i.price) * i.quantity,
    0
  );

  const discount_total = discount ? (cart_total / 100) * discount : 0;
  const grand_total = discount ? cart_total - discount_total : cart_total;

  return `${items.join("\n  ")}
  ${discount_total > 0 ? `Отстъпка ${discount_total.toFixed(2)} лв.<br />` : ""}
  Общо: <strong>${grand_total.toFixed(2)} лв.</strong><br />
  `;
};

const OrderItems = ({ r }) => {
  const { cart } = React.useContext(CartContext);

  return (
    <input ref={r} type="hidden" name="data[items]" value={formatItems(cart)} />
  );
};

export default OrderItems;
