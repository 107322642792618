import React from "react";
import PropTypes from "prop-types";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Button from "../frontend/Button";

const SpotlightImage = ({ image }) => (
  <div className="image-wrapper">
    <Img src={image} variant="image" />
  </div>
);

const Raiting = ({ raiting }) => {
  let starsList = [];

  for (let idx = 0; idx < raiting; idx++) {
    starsList.push(<i className="icon-star" key={idx} />);
  }

  return <div className="raiting">{starsList}</div>;
};

const SpotlightContent = ({
  title,
  text,
  quote,
  avatar,
  name,
  source,
  raiting,
  button,
}) => (
  <div className="content-wrapper description">
    <div className="content">
      <div className="content-inner">
        <h2>{title}</h2>
        <div className="text" dangerouslySetInnerHTML={{ __html: text }} />

        <q className="quote">{quote}</q>

        <div className="social">
          <div className="author">
            <Img src={avatar} variant="thumb" className="avatar" />
            <div className="author-info">
              <h6 className="name">{name}</h6>
              <p className="source">{source}</p>
            </div>
          </div>

          <Raiting raiting={raiting} />
        </div>

        <div className="button-wrapper">
          <Button settings={button} />
        </div>
      </div>
    </div>
  </div>
);

const SpotlightWidget = ({
  image,
  title,
  text,
  quote,
  avatar,
  name,
  source,
  raiting,
  button,
  variant,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="spotlight">
      <div className={`inner ${variant === "image-right" ? "reverse" : ""}`}>
        <SpotlightImage image={image} />

        <SpotlightContent
          title={title}
          text={text}
          quote={quote}
          avatar={avatar}
          name={name}
          source={source}
          raiting={raiting}
          button={button}
        />
      </div>
    </div>
  </Container>
);

SpotlightWidget.title = "Spotlight";
SpotlightWidget.category = "Highlights";

SpotlightWidget.defaultProps = {
  quote: "",
  avatar: {},
  name: "",
  source: "",
  button: {},
};

SpotlightWidget.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  quote: PropTypes.string,
  avatar: PropTypes.any,
  image: PropTypes.any.isRequired,
  name: PropTypes.string,
  source: PropTypes.string,
  raiting: PropTypes.number.isRequired,
  button: PropTypes.object,
  containerSettings: PropTypes.object.isRequired,
};

SpotlightWidget.dialogSize = "large";

SpotlightWidget.defaults = {
  title: "Вкусната храна е незаменим инструмент за добро събитие",
  text:
    "Опитай домашни вкусотии и международни деликатеси на добра цена всеки делничен ден. Нашето вкусно обедно меню включва салати, супи, скара, готвено, десерти, вегетариански и веган ястия. Хапни някъде наблизо - в ресторант Smokini, в центъра на град Пловдив, на ул. Отец Паисий 12.",
  quote:
    "I don’t know how much weight I’ve gained since I found this restaurant. :D Very delicious meals and waiting staff is friendly. Highly recommended.",
  avatar: "http://via.placeholder.com/40x40",
  image: "http://via.placeholder.com/540x540",
  name: "Tanya Vassileva",
  source: "On Facebook",
  raiting: 4,
  button: {
    label: "Разгледай цялото меню тук",
    link: "#",
    id: "",
    type: "primary",
    target: "_self",
    follow_link: "no-follow",
  },
  variant: "left",
  containerSettings: { theme: "white" },
};

SpotlightWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["image-left", "Image Left"],
      ["image-right", "Image Right"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  text: { type: "custom", component: RichText },
  quote: { type: "text" },
  avatar: { type: "custom", component: ImagePicker },
  name: { type: "text" },
  source: { type: "text" },
  raiting: {
    type: "select",
    options: [
      [1, 1],
      [2, 2],
      [3, 3],
      [4, 4],
      [5, 5],
    ],
  },
  button: { type: "button" },
};

export default SpotlightWidget;
