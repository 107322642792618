import Cookies from "js-cookie";
import useCart from "./useCart";

const useCookieCart = () => {
  const cart_cookie = Cookies.get("cart");
  const initial_cart = cart_cookie ? JSON.parse(cart_cookie) : [];
  const {
    cart,
    add: addCart,
    update: updateCart,
    remove: removeCart,
    clear: clearCart,
  } = useCart(initial_cart);

  const add = (item, quantity) => {
    const newCart = addCart(item, quantity);
    Cookies.set("cart", JSON.stringify(newCart));
  };

  const remove = (id) => {
    const newCart = removeCart(id);
    Cookies.set("cart", JSON.stringify(newCart));
  };

  const update = (id, attrs) => {
    const newCart = updateCart(id, attrs);
    Cookies.set("cart", JSON.stringify(newCart));
  };

  const clear = () => {
    clearCart();
    Cookies.set("cart", JSON.stringify([]));
  };

  return {
    cart,
    add,
    update,
    remove,
    clear,
  };
};

export default useCookieCart;
