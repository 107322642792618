import React from "react";
import { imageSrc } from "@raketa-cms/raketa-cms";
import { useCookieCart } from "../raketa-cart";
import StepInput from "./StepInput";

const rand = () => Math.random().toString(36).substring(7);

const AddToCart = ({ product, label, ...props }) => {
  const { cart, add, update, remove } = useCookieCart();

  const item = cart.find((i) => i.sku === product.sku);
  const inCart = item !== undefined;

  if (inCart) {
    return (
      <StepInput
        value={item.quantity}
        onChange={(quantity) => {
          if (quantity === 0) remove(product.sku);
          else update(product.sku, { quantity });

          window.location.href = "/menu";
        }}
      />
    );
  }

  return (
    <button
      type="button"
      onClick={() => {
        add(product, 1);
        window.location.href = "/menu";
      }}
      {...props}
    >
      {label}
    </button>
  );
};

const BuyButton = ({ label, product }) => (
  <AddToCart
    className="btn-primary-alt"
    label={label}
    product={{
      id: rand(),
      sku: `product-${product.id}`,
      title: product.title,
      price: product.price,
      image: imageSrc(product.image, "thumb"),
    }}
  />
);

export default BuyButton;
