import React from "react";

const TypeSelector = ({ types, value, onChange }) => (
  <div className="type-selector">
    {types.map((type) => (
      <div key={type.id}>
        <button
          type="button"
          className={type.id === value ? "active" : ""}
          onClick={() => onChange(type.id)}
        >
          <i className={`icon ${type.icon}`} />
          {type.label}
        </button>
      </div>
    ))}
  </div>
);

export default TypeSelector;
