import React from 'react';
import DatePicker from 'react-datepicker';

const DateInput = ({ label, error, value, onChange }) => {
  return (
    <div className={`form-group date-picker ${error ? 'has-error' : ''}`}>
      <label htmlFor="">{label} <span className="required">*</span></label>

      {(typeof window !== 'undefined') &&
        <DatePicker
          locale="en"
          selected={value}
          onChange={value => onChange(value)}
          className="icon-input icon-datepicker"
        />
      }

      {error && <span className="help-block">{error}</span>}
    </div>
  )
};

DateInput.defaultProps = { value: '' };

export default DateInput;
