import React from 'react';
import PropTypes from 'prop-types';

class MenuProvider extends React.Component {
  getChildContext() {
    const { lunchMenu, weeklyMenu } = this.props;

    return {
      lunchMenu,
      weeklyMenu,
    };
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

MenuProvider.childContextTypes = {
  lunchMenu: PropTypes.any,
  weeklyMenu: PropTypes.any,
};

export default MenuProvider;
