import React from "react";
import CheckoutLayout from "../frontend/CheckoutLayout";
import DeliveryForm from "./DeliveryForm";
import PickupForm from "./PickupForm";

const TYPES = [
  { id: "delivery", label: "Безплатна доставка", icon: "delivery" },
  { id: "pickup", label: "Вземи от ресторанта", icon: "pickup" },
  // { id: 3, label: "Хапни в ресторанта", icon: "book" },
];

const Checkout = ({ page_context, time_slots }) => {
  const [type, setType] = React.useState("delivery");

  return (
    <CheckoutLayout page_context={page_context}>
      <div className="black-bg spacing-top-large spacing-bottom-small">
        <div className="store-title">
          <div className="container">
            <h1 className="title">
              Детайли на поръчката{" "}
              <a href="/cart" className="btn-back">
                Назад към количката
              </a>
            </h1>
          </div>
        </div>
      </div>

      {type === "delivery" && (
        <DeliveryForm
          time_slots={time_slots}
          types={TYPES}
          type={type}
          onChangeType={setType}
        />
      )}

      {type === "pickup" && (
        <PickupForm
          time_slots={time_slots}
          types={TYPES}
          type={type}
          onChangeType={setType}
        />
      )}
    </CheckoutLayout>
  );
};

export default Checkout;
