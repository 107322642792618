import React from "react";
import { CartContext } from "../raketa-cart";
import StepInput from "./StepInput";

const AddToCart = ({ product, label, ...props }) => {
  const { cart, add, update, remove } = React.useContext(CartContext);

  const item = cart.find((i) => i.sku === product.sku);
  const inCart = item !== undefined;

  if (inCart) {
    return (
      <StepInput
        value={item.quantity}
        onChange={(quantity) => {
          if (quantity === 0) remove(product.sku);
          else update(product.sku, { quantity });
        }}
      />
    );
  }

  return (
    <button type="button" onClick={() => add(product, 1)} {...props}>
      {label}
    </button>
  );
};

export default AddToCart;
