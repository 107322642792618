import React from "react";
import PropTypes from "prop-types";
import { Container, Img, List, TextInput } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import formatPrice from "../formatPrice";

const MealsWidget = ({ list, containerSettings }, { language }) => (
  <Container settings={containerSettings}>
    <div className="menu-cards">
      <div className="container">
        <div className="menu-cards-wrapper">
          {list.map((meal, idx) => (
            <div className="card-col" key={idx}>
              <div className="menu-card">
                <a href={meal.link}>
                  <Img
                    src={meal.image}
                    variant="article_thumb"
                    alt={`${meal.title} ${
                      language === "bg" ? "снимка" : "photo"
                    }`}
                  />
                </a>

                <div className="text">
                  <div className="title">
                    <h6 className="title-text">
                      <a href="#">{meal.title}</a>
                    </h6>
                    <div className="price">
                      {formatPrice(meal.price, language)}
                    </div>
                  </div>

                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: meal.description }}
                  />
                  <div className="card-footer">
                    <p className="serving-info">
                      {meal.grams} <span className="separator"></span>{" "}
                      {meal.calories}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

MealsWidget.contextTypes = {
  language: PropTypes.any,
};

MealsWidget.title = "Meals";
MealsWidget.category = "Content";

MealsWidget.propTypes = {
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

MealsWidget.defaults = {
  list: [
    {
      id: 1,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 2,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 3,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 4,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 5,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 6,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 7,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 8,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
    {
      id: 9,
      link: "#",
      image: "http://placehold.it/360x240",
      title: "Пилешко филе със смокини и течно Бри",
      price: "99.99",
      description:
        "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
      grams: "330 г",
      calories: "999 kcal",
    },
  ],
  containerSettings: { theme: "dark" },
};

const MealItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <TextInput
      label="Price"
      onChange={(value) => onChangeItem("price", value)}
      value={settings.price}
    />

    <TextInput
      label="Grams"
      onChange={(value) => onChangeItem("grams", value)}
      value={settings.grams}
    />

    <TextInput
      label="Calories"
      onChange={(value) => onChangeItem("calories", value)}
      value={settings.calories}
    />
  </div>
);

MealsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      label="Meals"
      listItem={(settings, onChangeItem) => (
        <MealItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        link: "#",
        image: "http://placehold.it/360x240",
        title: "Пилешко филе със смокини и течно Бри",
        price: "99.99",
        description:
          "<p>с микс от избрани сирена, гарнирано с талиатели от моркови и два вида сусам</p>",
        grams: "330 г",
        calories: "999 kcal",
      }}
    />
  </div>
);

export default MealsWidget;
