import React, { useState } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import {
  Container,
  Img,
  List,
  TextInput,
  ButtonSettings,
} from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import Button from "../frontend/Button";

const AccentTestimonialWidget = ({ title, list, containerSettings }) => {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) swiper.slideNext();
  };

  const goPrev = () => {
    if (swiper !== null) swiper.slidePrev();
  };

  return (
    <Container className="accent-testimonial" settings={containerSettings}>
      <React.Fragment>
        {title && (
          <div className="container testimonial-list-title-wrapper">
            <h3 className="testimonial-list-title">{title}</h3>
          </div>
        )}

        <Swiper
          getSwiper={updateSwiper}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          keyboard={true}
          paginationClickable
          slidesPerView={1}
          spaceBetween={0}
          loop
          speed={500}
        >
          {list.map((slide, idx) => (
            <article key={idx} className="testimonial">
              <div className="testimonial-inner container">
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: slide.text }}
                />

                <div className="meta">
                  <div className="author">
                    <Img src={slide.avatar} variant="thumb" />

                    <div>
                      <h4 className="name">{slide.name}</h4>
                      <p className="description">{slide.description}</p>
                    </div>
                  </div>

                  <Button settings={slide.button} />
                </div>
              </div>
            </article>
          ))}
        </Swiper>

        <button
          onClick={goNext}
          className="swiper-button-next icon-arrow-right"
        />
        <button
          onClick={goPrev}
          className="swiper-button-prev icon-arrow-left"
        />
      </React.Fragment>
    </Container>
  );
};

AccentTestimonialWidget.title = "Accent Testimonial";
AccentTestimonialWidget.category = "Content";

AccentTestimonialWidget.defaultProps = {
  title: "",
};

AccentTestimonialWidget.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

AccentTestimonialWidget.defaults = {
  title: "Какво мислят организаторите на събития за нас?",
  list: [
    {
      id: 1,
      name: "Стефан Еленков",
      description: "Изпълнителен директор на Sofia Music Enterprise",
      avatar: "http://placehold.it/80x80",
      title: "Пъпешът - греховната полезна сладост, стичаща се по небцето",
      text:
        "<p>За да организираме концертите на Стинг и Шаги в Пловдив, потърсихме ресторант, чиято кухня не само може да предостави добра платформа за личния готвач на Стинг, но и да приготви хранта за групата и нашия екип. Проучихме няколко ресторанта в Пловдив, но никой от тях не покриваше нашите изисквания. Докато не открихме Smokini.</p> <p>Трудно се намират ресторанти, които са вещи в организирането на събития.Именно за това сме много щастливи, че намерихме такъв партньор в лицето на Smokini.Екипът им има богат опит в изнасянето на комплексни събития и могат да вземат бързи решения в интерес на гостите и събитието.При организирането на храненето имаше характерните за този ранг събития промени и предизвикателства, но за щастие Smokini бяха винаги отзивчиви и предлагаха подходящи решения.</p> <p>Храната и обслужването на Smokini бяха изключитено добри - гостите ни споделиха очарованието си и благодариха за вкусните вечери и обяди.</p>",
      button: {
        label: "ОРГАНИЗИРАЙ ТАКОВА СЪБИТИЕ",
        link: "#",
        id: "",
        type: "primary",
        target: "_self",
        follow_link: "no-follow",
      },
    },
    {
      id: 2,
      name: "Стефан Еленков",
      description: "Изпълнителен директор на Sofia Music Enterprise",
      avatar: "http://placehold.it/80x80",
      title: "Летни ястия - най-добрите храни за горещия",
      text:
        "<p>За да организираме концертите на Стинг и Шаги в Пловдив, потърсихме ресторант, чиято кухня не само може да предостави добра платформа за личния готвач на Стинг, но и да приготви хранта за групата и нашия екип. Проучихме няколко ресторанта в Пловдив, но никой от тях не покриваше нашите изисквания. Докато не открихме Smokini.</p> <p>Трудно се намират ресторанти, които са вещи в организирането на събития.Именно за това сме много щастливи, че намерихме такъв партньор в лицето на Smokini.Екипът им има богат опит в изнасянето на комплексни събития и могат да вземат бързи решения в интерес на гостите и събитието.При организирането на храненето имаше характерните за този ранг събития промени и предизвикателства, но за щастие Smokini бяха винаги отзивчиви и предлагаха подходящи решения.</p> <p>Храната и обслужването на Smokini бяха изключитено добри - гостите ни споделиха очарованието си и благодариха за вкусните вечери и обяди.</p>",
      button: {
        label: "ОРГАНИЗИРАЙ ТАКОВА СЪБИТИЕ",
        link: "#",
        id: "",
        type: "primary",
        target: "_self",
        follow_link: "no-follow",
      },
    },
    {
      id: 3,
      name: "Стефан Еленков",
      description: "Изпълнителен директор на Sofia Music Enterprise",
      avatar: "http://placehold.it/80x80",
      title: "Седемте имена на Пловдив - история и култура във вечния град",
      text:
        "<p>За да организираме концертите на Стинг и Шаги в Пловдив, потърсихме ресторант, чиято кухня не само може да предостави добра платформа за личния готвач на Стинг, но и да приготви хранта за групата и нашия екип. Проучихме няколко ресторанта в Пловдив, но никой от тях не покриваше нашите изисквания. Докато не открихме Smokini.</p> <p>Трудно се намират ресторанти, които са вещи в организирането на събития.Именно за това сме много щастливи, че намерихме такъв партньор в лицето на Smokini.Екипът им има богат опит в изнасянето на комплексни събития и могат да вземат бързи решения в интерес на гостите и събитието.При организирането на храненето имаше характерните за този ранг събития промени и предизвикателства, но за щастие Smokini бяха винаги отзивчиви и предлагаха подходящи решения.</p> <p>Храната и обслужването на Smokini бяха изключитено добри - гостите ни споделиха очарованието си и благодариха за вкусните вечери и обяди.</p>",
      button: {
        label: "ОРГАНИЗИРАЙ ТАКОВА СЪБИТИЕ",
        link: "#",
        id: "",
        type: "primary",
        target: "_self",
        follow_link: "no-follow",
      },
    },
  ],
  containerSettings: { theme: "dark", spacing: "both-large" },
};

const ArticleListItem = ({ settings, onChangeItem }) => (
  <div>
    <RichText
      label="Text"
      onChange={(value) => onChangeItem("text", value)}
      value={settings.text}
    />

    <TextInput
      label="Name"
      onChange={(value) => onChangeItem("name", value)}
      value={settings.name}
    />

    <TextInput
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <ImagePicker
      label="Avatar"
      onChange={(value) => onChangeItem("avatar", value)}
      value={settings.avatar}
    />

    <ButtonSettings
      button_settings_title="Button"
      value={settings.button}
      onChange={(values) => onChangeItem("button", values)}
    />
  </div>
);

ArticleListItem.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

AccentTestimonialWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Title"
      onChange={(value) => onChange("title", value)}
      value={settings.title}
    />

    <List
      label="Testimonial Items"
      listItem={(settings, onChangeItem) => (
        <ArticleListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="name"
      template={{
        name: "Стефан Еленков",
        description: "Изпълнителен директор на Sofia Music Enterprise",
        avatar: "http://placehold.it/80x80",
        text:
          "<p>За да организираме концертите на Стинг и Шаги в Пловдив, потърсихме ресторант, чиято кухня не само може да предостави добра платформа за личния готвач на Стинг, но и да приготви хранта за групата и нашия екип. Проучихме няколко ресторанта в Пловдив, но никой от тях не покриваше нашите изисквания. Докато не открихме Smokini.</p> <p>Трудно се намират ресторанти, които са вещи в организирането на събития.Именно за това сме много щастливи, че намерихме такъв партньор в лицето на Smokini.Екипът им има богат опит в изнасянето на комплексни събития и могат да вземат бързи решения в интерес на гостите и събитието.При организирането на храненето имаше характерните за този ранг събития промени и предизвикателства, но за щастие Smokini бяха винаги отзивчиви и предлагаха подходящи решения.</p> <p>Храната и обслужването на Smokini бяха изключитено добри - гостите ни споделиха очарованието си и благодариха за вкусните вечери и обяди.</p>",
        button: ButtonSettings.defaults,
      }}
    />
  </div>
);

export default AccentTestimonialWidget;
