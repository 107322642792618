import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";

const layoutVariant = {
  normal: "col-12",
  shifted: "shift-2",
  shiftedRight: "col-8",
};

const SectionTitleWidget = ({ title, variant, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container">
      <div className="row">
        <div className={`section-title-wrapper ${layoutVariant[variant]}`}>
          <h2 className="section-title">{title}</h2>
        </div>
      </div>
    </div>
  </Container>
);

SectionTitleWidget.title = "Section Title";
SectionTitleWidget.category = "General";

SectionTitleWidget.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

SectionTitleWidget.defaults = {
  title: "Section title",
  variant: "normal",
  containerSettings: { theme: "light" },
};

SectionTitleWidget.adminFields = {
  title: { type: "text" },
  variant: {
    type: "select",
    options: [
      ["normal", "Normal"],
      ["shifted", "Shifted"],
      ["shiftedRight", "Shifted Right"],
    ],
  },
};

export default SectionTitleWidget;
