import React, { useState }  from 'react';

const Star = ({ color, onClick }) => (
  <button type="button" onClick={onClick}>
    <svg width="38px" height="37px" viewBox="0 0 38 37" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Star-Copy" fill={`#${color}`} points="19 31.9628906 7.24429495 36.1803399 7.62261492 23.6967365 -0.0211303259 13.8196601 11.9683893 10.3218182 19 0 26.0316107 10.3218182 38.0211303 13.8196601 30.3773851 23.6967365 30.755705 36.1803399"></polygon>
      </g>
    </svg>
  </button>
);

const Rating = ({ name }) =>  {
  const [starsCount, setStars] = useState(0);

  return (
    <div className="rating">
      <Star onClick={() => starsCount === 1 ? setStars(0) : setStars(1)} color={starsCount > 0 ? 'FD9B3E' : 'D8D8D8'} />
      <Star onClick={() => setStars(2)} color={starsCount > 1 ? 'FD9B3E' : 'D8D8D8'} />
      <Star onClick={() => setStars(3)} color={starsCount > 2 ? 'FD9B3E' : 'D8D8D8'} />
      <Star onClick={() => setStars(4)} color={starsCount > 3 ? 'FD9B3E' : 'D8D8D8'} />
      <Star onClick={() => setStars(5)} color={starsCount === 5 ? 'FD9B3E' : 'D8D8D8'} />

      <input type="hidden" name={name} value={starsCount} />
    </div>
  );
}

export default Rating;
