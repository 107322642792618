import React from "react";
import PropTypes from "prop-types";
import { Container } from "@raketa-cms/raketa-cms";
import Button from "../frontend/Button";

const CtaWidget = ({ title, button, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cta">
      <div className="container">
        <Button settings={button} />
      </div>
    </div>
  </Container>
);

CtaWidget.title = "Call to action";
CtaWidget.category = "Lead Generation";

CtaWidget.propTypes = {
  button: PropTypes.object.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

CtaWidget.defaults = {
  button: {
    label: "Button",
    link: "#",
    id: "",
    type: "primary",
    target: "_self",
    follow_link: "no-follow",
  },
  containerSettings: { theme: "dark" },
};

CtaWidget.adminFields = {
  button: { type: "button" },
};

export default CtaWidget;
