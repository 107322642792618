import React from 'react';

class CtaBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  handleScrollToFooter() {
    const footerHeight = document.querySelector('.site-footer').offsetHeight;
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;

    if (windowBottom >= docHeight - (footerHeight / 2)) {
      this.setState({ isVisible: false });
    } else {
      this.setState({ isVisible: true });
    }
  }

  componentDidMount() {
    if (window.innerWidth < 940) {
      this.setState({ isVisible: true });

      window.addEventListener("scroll", () => this.handleScrollToFooter());
    }
  }

  render() {
    const { cta_title, cta_subtitle, cta_button_label, cta_button_url  } = this.props;

    return (
      <div className={`cta-banner ${this.state.isVisible ? '' : 'hide'}`}>
        <div className="text">
          <h6 className="title">{cta_title}</h6>
          <p className="note">{cta_subtitle}</p>
        </div>
        <a href={cta_button_url} className="btn-primary-alt" id="mobile-cta">{cta_button_label}</a>
      </div>
    );
  }
}

export default CtaBanner;
