import React from "react";
import { CartContext } from "../raketa-cart";

const throttle = (fn, threshhold, scope) => {
  threshhold || (threshhold = 250);
  let last, deferTimer;
  return () => {
    let context = scope || this;

    let now = +new Date(),
      args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
};

const SiteHeader = ({ page_context }) => {
  const {
    logo,
    logo_dark,
    activePage,
    header_menu,
    langLinks,
    currentLang,
    cart_active,
  } = page_context;
  console.log("ll", logo_dark);
  const [isNavOpen, setNavOpen] = React.useState(false);
  const [currentLogo, setCurrentLogo] = React.useState(logo);
  const { cart = [] } = React.useContext(CartContext);

  const cart_total =
    cart.length > 0
      ? cart.reduce((acc, i) => acc + parseFloat(i.price) * i.quantity, 0)
      : 0;

  const oppositeLangLink = langLinks.find(
    (link) => link.locale !== currentLang
  );

  const mobileHeaderTransition = (window, siteHeader) => {
    if (window.innerWidth > 939) return;
    var headerHeight = siteHeader.offsetHeight;
    var lastScrollPosition = 0;

    window.addEventListener(
      "scroll",
      throttle(function () {
        var newScrollPosition = window.scrollY;

        if (newScrollPosition > headerHeight) {
          siteHeader.classList.add("hide-header");
          siteHeader.classList.remove("fixed-header");
        } else {
          siteHeader.classList.remove("fixed-header");
          siteHeader.classList.remove("hide-header");
        }

        if (newScrollPosition < lastScrollPosition) {
          if (
            newScrollPosition >=
            document.querySelector("body").offsetHeight -
              window.innerHeight -
              100
          ) {
            return;
          } else {
            siteHeader.classList.add("fixed-header");
            siteHeader.classList.remove("hide-header");
          }
        } else {
          siteHeader.classList.remove("fixed-header");
          siteHeader.classList.add("hide-header");
        }

        if (newScrollPosition <= 0) {
          siteHeader.classList.remove("fixed-header");
          siteHeader.classList.remove("hide-header");
          return;
        }

        if (
          newScrollPosition + window.innerHeight >=
          document.querySelector("body").offsetHeight
        ) {
          siteHeader.classList.add("hide-header");
        }

        lastScrollPosition = newScrollPosition;
      }, 400)
    );
  };

  const desktopStickyHeader = (window, siteHeader) => {
    if (window.innerWidth < 939) return;

    window.addEventListener("scroll", () => {
      if (window.scrollY === 0 || window.scrollY <= 5) {
        siteHeader.classList.remove("desktop-sticky");
        setCurrentLogo(logo);
      } else {
        siteHeader.classList.add("desktop-sticky");
        setCurrentLogo(logo_dark);
      }
    });
  };

  React.useEffect(() => {
    const siteHeader = document.querySelector(".site-header");
    desktopStickyHeader(window, siteHeader);
    mobileHeaderTransition(window, siteHeader);
  });

  const isLinkActive = (link) => link.indexOf(activePage) !== -1;

  return (
    <header className={`site-header ${isNavOpen ? "nav-open" : ""}`}>
      <div className="container">
        <a
          href={`/${currentLang}`}
          className="header-logo"
          style={{ backgroundImage: `url(${currentLogo})` }}
        />

        <nav>
          <div className="links-wrapper">
            {header_menu.items.map((item, idx) => (
              <a
                href={item.link}
                className={isLinkActive(item.link) ? "active" : ""}
                key={idx}
              >
                {item.label}
              </a>
            ))}
            <a href={oppositeLangLink.url} className="lang-link">
              {oppositeLangLink.label}
            </a>

            {cart_active && (
              <a href="/cart" className="cart-preview">
                <span>{cart_total.toFixed(2)} лв.</span>
              </a>
            )}
          </div>

          <div className="language-bar">
            {langLinks.map((link, idx) => (
              <a
                href={link.url}
                key={idx}
                className={link.locale === currentLang ? "active" : ""}
              >
                {link.label}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <button
        className={`menu-trigger ${isNavOpen ? "close-trigger" : ""}`}
        onClick={() => setNavOpen(!isNavOpen)}
      >
        <span className="triger-item"></span>
        <span className="triger-item"></span>
        <span className="triger-item"></span>
      </button>
    </header>
  );
};

export default SiteHeader;
