import React from "react";
import { CartContext } from "../raketa-cart";
import StepInput from "../frontend/StepInput";
import AddToCart from "../frontend/AddToCart";
import getDataLayer from "./getDataLayer";

const rand = () => Math.random().toString(36).substring(7);

const CartTable = ({ type, discount }) => {
  const { cart, update, remove } = React.useContext(CartContext);

  const cart_total = cart.reduce(
    (acc, i) => acc + parseFloat(i.price) * i.quantity,
    0
  );

  const discount_total = discount ? (cart_total / 100) * discount : 0;
  const grand_total = discount ? cart_total - discount_total : cart_total;

  const hasUtensils =
    cart.findIndex((i) => i.sku === "product-utensils") !== -1;

  return (
    <>
      <hr />

      <table className="cart-table checkout-table">
        <tbody>
          {cart.map((item) => (
            <tr key={item.id}>
              <td>{item.title}</td>
              <td className="bool" style={{ width: "100px" }}>
                <StepInput
                  value={item.quantity}
                  onChange={(quantity) => {
                    if (quantity === 0) remove(item.sku);
                    else update(item.sku, { quantity });
                  }}
                />
              </td>
              <td className="number" style={{ width: "100px" }}>
                {(parseFloat(item.price) * item.quantity).toFixed(2)} лв.
              </td>
            </tr>
          ))}

          {!hasUtensils && (
            <tr>
              <td colSpan={3}>
                <AddToCart
                  className="btn-utensils"
                  label="Добави прибори и салфетки"
                  product={{
                    id: rand(),
                    sku: `product-utensils`,
                    title: "Прибори и салфетки",
                    price: 0,
                  }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <hr />

      <div className="checkout-row">
        <span className="amount">{cart_total.toFixed(2)} лв.</span> Ястия и
        напитки
      </div>
      {type === "delivery" && (
        <div className="amount" className="checkout-row">
          <span className="amount">0,00 лв.</span> Безплатна доставка
        </div>
      )}
      {type === "pickup" && (
        <div className="checkout-row">
          <span className="amount">-{discount_total.toFixed(2)} лв.</span>{" "}
          Отстъпка
        </div>
      )}
      <hr className="hr-short" />
      <div className="checkout-row" style={{ opacity: 1 }}>
        <span className="amount">{grand_total.toFixed(2)} лв.</span> Общо в лева
        с ДДС
      </div>

      <div
        dangerouslySetInnerHTML={{
          __html: getDataLayer(cart.length, cart_total.toFixed(2)),
        }}
      />
    </>
  );
};

export default CartTable;
